import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import JourneyBalkans from '@/components/JourneyBalkans.vue'
import JourneySoutheastAsia from '@/components/JourneySoutheastAsia.vue'
import Post from '@/components/Post.vue'
import NotFound from '@/components/NotFound.vue'

import postsBalkans from './posts/00_balkans/posts'
import postsSoutheastAsia from './posts/01_southeast_asia/posts'

const generatePostRoutes = (posts: any[]) => {
  return posts.map(post => ({
    path: `${post.path}`,
    component: Post,
    props: { post: post.data }
  }))
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/balkans/thessaloniki-1', // Redirect from root
  },
  {
    path: '/balkans',
    component: JourneyBalkans,
    children: [
      {
        path: '',
        redirect: '/balkans/thessaloniki-1',
      },
      ...generatePostRoutes(postsBalkans),
    ],
  },
  {
    path: '/southeast-asia',
    component: JourneySoutheastAsia,
    children: [
      {
        path: '',
        redirect: '/southeast-asia/bangkok-1',
      },
      ...generatePostRoutes(postsSoutheastAsia),
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
