import { PostProps } from "@/types"

const post14: PostProps = {
  key: 14,
  journeyKey: 0,
  x: '60%',
  y: '9%',
  zIndex: 130,
  location: 'Istanbul',
  title: 'Messy welcome to the city with a good ending',
  content: [
    {
      type: 'text',
      value: [
        `I was pretty lucky with this bus. I had a whole double seat for me, but the bus was so empty I could also lie in the back with all 5 seats, so I could stretch out my legs. The only moment I didn't do that was when one of the bus drivers needed to nap there.`,
        `The drivers gave everyone juice and a cookie. Here, I experienced the first example of Turkish hospitality. The drivers didn't speak English, but they were still pretty engaging. When we were crossing the border, I actually managed to fall asleep so hard that while everyone got off the bus, I was still sleeping, safe and sound. The bus drivers had to go back to the empty bus to wake me up. I grabbed my passport, rushed out of the bus and presented it to the policemen. I wasn't wearing my shoes. Then I realized we had to stay outside while they performed the whole border control, so I had to run again back to the bus to grab my shoes.`,
        `When I was leaving the bus, the drivers were laughing about it all: 'Very asleep! Very asleep!' They repeated.`,
        `By the time I arrived in Istanbul (so well rested from my deep sleep), my mood went really down. It was the election night, so I watched the intermediate results and saw the final result as well. I was really disappointed in Slovakia, almost to the point of giving up on it completely. I don't want to associate with people who forget their past and repeat their mistakes. Or with people susceptible to politicians misusing hate and fear, and to polarization. Or those oriented more towards Russia rather than the west. I could name many more troublesome things the winning party represents, but let's talk about Turkish hospitality again.`,
        `In the rainy dawn Istanbul bus station, I found an open café, with plugs and wifi and everything I needed. I ordered coffee and received a free cake with it. The waiter made sure to inform me when the sim card shop I asked about opens. He also gave me his Istanbul travel card, so that I could use the station toilets. And just when I thought this was the peak of his niceness, he brought me another coffee.`,
        `I did not have a travel card figured out for Istanbul and I only saw information on the internet which was warning about high prices. I later found out that the Istanbulkart is actually really cheap and the travel is cheap too, but this was way too hard to get through all the Google ads and tourist traps to buy some crazy city card for 200€ or so. At this point, I was clueless and decided to walk to my hostel, which was 2 hours away.`,
        `The bus station roads were complicated, I made wrong turns and had to return a few times which prolonged the route. I also didn't expect it would get so hilly and I definitely didn't expect it would start raining. My backpack was too heavy and my spirits were down. At some point, I did find a wifi and there I looked up an appropriate bus and just hoped I could pay by card. It was a success, but when I got off the bus, I still had to walk about 15 minutes and at this point, it was pouring really hard.`,
        `When I checked in, I was exhausted and messy. The first thing I needed to do was to cancel a walking trip I had planned for this afternoon. The second thing, well, I wasn't sure how to tackle this. I was incredibly hungry, but to go for groceries, I needed to shower, but to shower, I needed to unpack, but while unpacking, I got this urge to also immediately tidy everything up, for which I would need to do laundry as well. But I was also too hungry to do all this! At some point in this mess, I was in the shower and the shower hose came loose. So I needed to dry up, and go tell the receptionist what happened. He luckily replaced the shower hose immediately.`,
        `I canceled my evening walk too. How I thought I was gonna do two walks in one such hard day, it amuses me.`,
        `At some point, I was clean, laundry was done, I made a big curry pot and I was having a call with my loved ones. I was thinking back to these little girls I met in the grocery store, who heard me talk in English and got really excited. They came back to me whenever they could think of another thing they could say in English and cheered and jumped from excitement when I replied. It warms my heart.`
      ]
    },
    {
      type: 'text',
      value: [
        `The next day, I was ready to work. The wifi at the hostel was not great and it was getting on my nerves, so I decided to go to Starbucks (safe choice, right?). Before I left the hostel, I got stuck in a confusing situation. The receptionist gave me his phone and someone was on it. This person didn't speak English and I was really confused why the receptionist wanted me to talk to them. At some point, the caller said 'I am outside' so I went outside and met him there. This guy was looking around apartments and I asked him who he was. He called his wife who speaks English and she explained that he is from the gas company and he is there to check gas. I wanted to let him in the hostel building but I knew it wasn't my place to decide to just let someone inside. So I told him to wait outside and I went to get the receptionist. He was, however, gone now. So I called him with the help of another resident. The receptionist sounded confused and thrown off, he kinda seemed like that also when handing me the phone. Apparently, he had to rush to the hospital urgently. I understood that just as he was leaving, he needed to deal with the gas guy but didn't have time for him so he delegated that to me and hoped for the best. I went outside now to get the gas guy but he was gone.`,
        `The mess continued even when this situation was over. I walked to the closest Starbucks and when I entered, there were turnstiles and people getting inside seemed to have some sort of card I didn't have. I asked at the reception, perplexed: 'Excuse me, is this the entrance to Starbucks?'. She said: 'only university students'. I looked around and realize I'm inside a university building. I asked her if she knew any other Starbucks closeby, to which she replied by repeating: 'only university students'.`,
        `I managed to find wifi and googled another Starbucks, about 30 minutes away. Before I ordered, I asked whether they have wifi. He said: 'you can get access at the reception'. I was now inside a hotel lobby. 'Is this Starbucks only for hotel guests?' I asked. He confirmed, but pointed me to another Starbucks 10 minutes away.`,
        `I entered this Starbucks and it finally seemed like a public one. Although, probably too public. All seats were taken and there was an incredibly long line. Even if I did get a seat, I would feel stupid spending 4 hours in a place, where people spend at most 15 minutes.`,
        `Guys, working from abroad can be tough sometimes. I had to again search for another place, and I gave up on Starbucks as the 'safe' option. I found a big café, entered and asked for power outlets. There was only one, at a table for 4, so I stayed there until the cafe got full and I needed to free that table. The wifi here also wasn't perfect, but I was able to get my work done.`,
        `After this experience, I knew I had to buy a sim card (I refused the one at the bus station because it was too expensive). I paid the same price this time, but I didn't mind anymore.`
      ]
    },
    {
      type: 'text',
      value: [
        `One good thing about today is that I had a walking tour! Before I made it to the meeting point, I was strolling through these shopping streets and it must've been a restocking hour for, well, everyone! The streets were full of levels and levels of boxes. It was like a labyrinth, I was trying to make my way through it. So we're people with motorbikes and other vehicles of all sizes and shapes, which were only carrying more boxes, also stacked up to the point I was worried they'd come falling down. On the ground, there were some empty and torn up boxes and a lot of other mess. This is also Istanbul, I'm thinking.`,
        `The walking tour was informative, mostly about food, coffee and more hidden places of Istanbul. We went to Rustem Pasha Mosque, which our guide labelled as 'the real blue mosque'. Apparently, it is more blue than the Blue Mosque. We walked through the spice market and some parts of the main market too. He showed us a very very old coffee brewing machine while telling us a story of the Turkish coffee. I learned that Turkish coffee was brought from Yemen by the Turkish governor of Yemen in the 16th century. They tried a new method of brewing and grinding the coffee before repairing the drink. Otherwise people would try to soak coffee beans in water just like you do with tea. The new Turkish coffee got spread to France and England and the rest of the world.`,
        `We crossed the Galata bridge, where many people stood with their fishing rods on the top, and many other people were eating fish in the restaurants on the bottom.`,
        `The Galatian (or New European) side of Istanbul had a much stronger European feel due to its architecture, as it was settled by and built by Europeans. Here, we got on the second world's oldest metro. We were shown a shopping center, which some time ago used to be an extremely fancy movie theatre. Closeby, there was an extremely fancy hotel, where movie stars would stay in for their premieres at the theatre.`,
        `The Galata tower looked nice from the outside, but the guide gave us a tip to go to a rooftop cafe closeby which has a similarly breathtaking view. This way, one can save 25€ on a ticket and still see Istanbul from this angle. That's what I did.`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post14/1.jpg',
          description: ['finally a good place to work'],
        },
        {
          src: '/images/00_balkans/post14/2.jpg',
          description: ['Grand Bazaar in Istanbul'],
        },
        {
          src: '/images/00_balkans/post14/3.jpg',
          description: ['decorated ceramics'],
        },
        {
          src: '/images/00_balkans/post14/4.jpg',
          description: ['a crazy variety of colorful spices'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `When the tour was over, the sun was already down. I walked through the DNA staircase. I made my way back to the hostel through nice shortcuts and a way faster and smoother route than Google maps suggested. Don't listen to Google maps in Istanbul, it does not know many roads there have crosswalks, apparently…`,
        `I had a good end to this day. My new sim-card gave me security. The walking tour gave me a bit of direction and guidance to my first messy experience with Istanbul.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post14/5.jpg',
          description: ['the `real` blue mosque', 'bluer than the Blue Mosque'],
        },
        {
          src: '/images/00_balkans/post14/6.jpg',
          description: ['the iconic hostorical tram'],
        },
        {
          src: '/images/00_balkans/post14/7.jpg',
          description: ['the shopping center', '(previously a famous movie theatre)'],
        },
        {
          src: '/images/00_balkans/post14/8.jpg',
          description: ['the view on the Galata tower from a cafe'],
        },
      ]
    },
  ]
  ,
  signature: '01/10/2023 - 02/10/2023'
}

export default post14