import { PostProps } from "@/types"

const post27: PostProps = {
  key: 27,
  journeyKey: 0,
  x: '78%',
  y: '88%',
  location: 'Paphos',
  title: ' A full-day adventure on the bike, a goodbye',
  content: [
    {
      type: 'text',
      value: [
        `Today, we had a great advantage compared to yesterday. We had food and a bike. We asked the receptionist for a water refill from their filtered water in the kitchen. We started doing this every day - giving three 1L bottles away and receiving them full of water with a big smile. The receptionist and other workers became like a family - since we were the only stable guests. In case there was someone new appearing in the villa, they left again the next morning.`,
        `We hopped on our bikes heading towards an adventure. We dropped them at the Small Church. We went inside, then relaxed in the church’s park. Someone called us into the church to eat some cream cakes for us. Great start of the day!`,
        `Then we left the bikes behind and continued onto the hiking trail to the Avakas Gorge. The gorge offered some amazing scenery. The temperature was pleasant there. We saw several big lizards. This spot was quite popular amongst the tourists, as there were many big groups of people in a good mood all around.`,
        `Next, we planned to hike to Lara beach. The first car we stopped immediately, but we sat in it only 30 seconds - then they had to turn towards a restaurant. We still accepted this, because it was terribly hot outside and the car had a nice AC. After that, we struggled to find a car and sat down inside a sandy rock cave. We watched the road, but apparently there were very few cars going in our direction.`,
        `Eventually, we decided to start walking. And now as we finally gave up, a car appeared and immediately stopped. We were very glad - otherwise we would have to walk on a dusty road for an hour at noon in the burning sun.`,
        `Lara beach was full of turtle nests. We walked around them with care and read information boards about the life cycle of turtles. We were a bit too late in the season to see them on the beach, but we still attempted to find them in the sea.`,
        `We also moved on to a tiny beach right next to Lara to try out our luck there. We did not see the turtles despite swimming rather far (while keeping close to the side of the shore). Instead, we discovered a cave which we explored for a while.`,
        `The sun was going down and we were now leaving the beach. We knew if we wanted to catch a car, we would have to go before all the people left the beaches after the sunset. However, we were pretty lucky again. We got a lift from a British couple who went here on vacation. We stopped midway for a nice picture of the sunset. The couple dropped us off close to our church, where we picked up our bikes. I made a bad mistake of using the toilet in the back of the church garden, which seemed to not have been used for 10 years (electricity and water was out too).`,
        `The ride back home in the dark warm night was again really romantic.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: `/images/00_balkans/post27/1.jpg`,
          description: ['the church where we left our bikes and ate good cakes'],
        },
        {
          src: `/images/00_balkans/post27/2.jpg`,
          description: ['Avakas Gorge'],
        },
        {
          src: `/images/00_balkans/post27/3.jpg`,
          description: ['almost at the top of the gorge'],
        },
        {
          src: `/images/00_balkans/post27/4.jpg`,
          description: ['on our way to the salty refreshment after the hike'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `The remaining days, we decided to take it easy. We needed to focus more on work, and after work, we preferred to relax this time. We dedicated time for sitting at the beach. Snorkelling in some caves closeby. Observing a shipwreck and reminiscing about our strange, stressful, interesting and beautiful Cyprus experience. We watched heartwarming comedies and observed the partial moon eclipse too.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: `/images/00_balkans/post27/5.jpg`,
          description: ['the shipwreck at the beach in town'],
        },
        {
          src: `/images/00_balkans/post27/7.jpg`,
          description: ['sunset we saw while hitchiking back to the church'],
        },
        {
          src: `/images/00_balkans/post27/6.jpg`,
          description: ['the church at the sunset when we picked up the bikes'],
        },
        {
          src: `/images/00_balkans/post27/8.jpg`,
          description: ['we managed to spot the moon eclipse!'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `On the last day, we packed our big bags and to utilise all the food, Thomas made maybe 10 giant bread sandwiches with an alarming amount of cheese. We returned our bikes and sat in Costa cafe in the outside space. We had the audacity to eat the bread there and maybe by karma, or by the fat-percentage in those sandwiches, we got sick from them. In this non-romantic context, we said goodbye to each other. I had a plane to catch back home and Thomas flew several hours later to his place.`,
        `Just a cherry on top: the bus stop to the airport was omitted due to some construction work, so my bus circled around the place where I waited. This way, I had to improvise: I got my last hitchhiking experience. The guy talked about life in Cyprus. He said the prices here skyrocketed after corona and people misused the situation and kept bloating the prices. It became hard for Cypriots. Cyprus is now even more international, as foreign companies make their business in real estate here. I indeed saw an insane amount of new hotels and villas being built by some extra wealthy fancy companies.`,
        `The kind guy dropped me off right in front of the departures. With my rounded up knowledge about Cyprus, I boarded my plane and waved it goodbye.`
      ]
    },
  ],
  signature: `28/10/2023 - 01/11/2023`
}

export default post27