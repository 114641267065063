
import { PostProps } from "@/types"

const post8: PostProps = {
  key: 8,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Mekong',
  title: 'Traveling for two days on a slow boat through the Mekong river',
  content: [ 
    {
      type: 'text',
      value: [
        `I wake up refreshed after a good night's sleep. I catch my bus at 7:30. This is another funny miniature bus with small seats, and the door left open for more fresh air.`,
        `Today, I am crossing the border to go to Koh Kong. This is a town just on the other side of the Mekong river, which divides Thailand and Laos. I will spend a night there, then take a slow boat on the Mekong river for two days, into the town in the north of Laos called Luang Prabang.`,
        `In between the borders, I take a shuttle bus. On the Lao border, I have to fill out some papers, but things go fast and smoothly since I already have an e-visa. I find a taxi that can bring me from the border to the center of Koh Kong. The taxi driver asks way too much money but I do not have any other choice. In Thailand, people didn’t seem to misuse the fact that I am a tourist in need, the prices were quite fixed for both locals and tourists. He is also rather rude, which is definitely the first time I have experienced this in Southeast Asia.`,
        `I join the taxi with an American dude who owns weed farms in Thailand (it is decriminalised there). He has to spend some time in Laos since his Thai visa is running out.`,
        `In my accommodation, I can look over the river. The room is humble. The lady who takes care of the place handles everything. She books the boat for me and promises good seats in the front (since I am booking in advance and not on the same day). She gives me a sim card, cooks dinner for me, shows me where to withdraw cash. Like a mama. Everything is taken care of. In the afternoon, I work from my room and I am quite at peace.`
      ],
    },
    {
      type: 'text',
      value: [
        `In the morning, the hostel mama gives me breakfast and packs some lunch for me (since there is not much food available at the boat). A taxi comes and picks me up along with some other travellers. I meet a nice Canadian girl and we find out we have seats next to each other.`,
        `The boat is completely wooden and rather long. It is open with no windows, and a wooden roof which sits on top of pillars. There are maybe 4 seats on each side, and an aisle in between. The seats are rather comfortable; they look like seats in an older car. Everyone is looking around curiously, everyone looks excited.`,
        `The way they store all the big backpacks is rather funny. They remove the long wooden tiles from the floor and throw the backpacks into the hole that opens - then put the tiles back to close the hole. There is a guy walking on the roof of the boat, looking at all incoming tourists, perhaps counting them.`,
        `For a while, the boat is full, everyone patiently sitting on their seat, waiting for the boat to start. Finally, we hear a loud engine and the wind is rising slowly. Soon enough, we are moving through the river. There is a pleasant breeze in my hair. Everyone starts looking to the sides to observe the beautiful nature. Mountains change to smaller ones, then bigger ones again. The engine is roaring and even though it is called a ‘slow’ boat, it seems pretty fast to me. We have to start talking loudly to hear each other.`,
        `My Canadian friend’s name is Kolby. She is really smart and we talk about everything. We compare Canada with Europe, Europe with America and Canada with America. Kolby has a lot of knowledge and I am learning a lot about the system in Canada and America, their history, the way they treated indigenous people, the way they treat them now. It feels like a big insight into the world and society… I just love listening to people who know a lot about something.`,
        `Eventually, Kolby and I take a break from long talks and return to our books. I try to meditate on the boat and it surprises me how pleasant that can be, despite the loud sound of the engine. I find it important to learn to meditate in loud and busy places.`,
        `Sometimes, the boat takes small stops for some villagers to get on the boat, or to just walk through it offering to sell some food or goods. Anytime the boat is about to stop, a group of adorable children run with all their energy down the sandy hill towards the river to get as close as possible. They greet us and wave to us excitedly.`
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post8/1.jpg',
          description: ['the view from my hostel on the Mekong river'],
        },
        {
          src: '/images/01_southeast_asia/post8/2.jpg',
          description: ['boarding the boat'],
        },
        {
          src: '/images/01_southeast_asia/post8/3.jpg',
          description: ['an all-day long view from the boat'],
        },
        {
          src: '/images/01_southeast_asia/post8/4.jpg',
          description: ['the sunset at Pak Ben'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `Slowly, we are approaching our stop for the night. The sun is slowly setting and it creates a very romantic atmosphere. I find a lot of peace observing the orange colour reflected in the river, as the mountains become more and more steep and pronounced. The further we go on the river, the more mountainous it gets.`,
        `We are arriving in Pak Ben village. There are tuk-tuks waiting for us to bring us to our designated hostels. After accommodating myself in the hostel for 8 euros, I take a walk in the village to find dinner. I do not know where Kolby is and all the other people are just big french-speaking groups. So I go alone.`,
        `It is dark now. I find a nice Indian restaurant with a pleasant atmosphere. I see a lot of people sharing dinner together and I am starting to feel lonely. I am aware of how lucky it is to travel and to meet so many amazing travellers. However, it has been almost two weeks that I have not felt very stable. I have been on the road constantly. The connections I do get are mostly fleeting. People move on and then you need a restart again.`,
        `I walk back to the hostel, calling some friends from home. I am feeling lonely. Sad. Homesick. Weak. I forget my turn and I get lost. A child on the street asks for my muffin. ‘Are you hungry?’ I ask immediately. He nods intensely. I panic and give him the muffin. Within seconds, I realize he looks perfectly healthy and happy. He was not hungry and I am not teaching him the right lesson by giving him what he asks. I hope next time I am more prepared for such a situation.`,
        `At the hostel, the French people are drinking and talking outside. I walk to the room and I am the only one who goes to sleep at this early hour.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post8/5.jpg',
          description: ['the main street in Pak Ben'],
        },
        {
          src: '/images/01_southeast_asia/post8/6.jpg',
          description: ['the full passenger capacity of the boat'],
        },
        {
          src: '/images/01_southeast_asia/post8/7.jpg',
          description: ['the POV of the captain'],
        },
        {
          src: '/images/01_southeast_asia/post8/8.jpg',
          description: ['the sun reflecting on the water'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `I slept absolutely amazing, I feel completely rested. I leave the hostel with the last tuk-tuk. I am happy to see that Kolby is holding a place for me. The only difference between the boat ride today and the one yesterday is that today we don't have allocated seats, so we sit somewhere in the middle of the boat.`
      ],
    },
    {
      type: 'text',
      value: [
        `Kolby and I arrive in Luang Prabang in the evening, take a taxi to the center together, and find the market. Here, there are plenty of food options, also for vegetarians. We share dinner and talk about future travel plans.`,
        `At my hostel, I meet with Patrik. This is my first planned meet-up at a hostel. I know Patrik from an erasmus+ project that I attended, but he was not participating in. He had more to do with organization. We followed each other on Instagram but did not know each other personally. Patrik saw I was also traveling Asia and reached out to me. We found out we will both be arriving in Luang Prabang around the same time - him from Cambodia and me from Laos. So now we are here, meeting up at the rooftop, getting to know each other and playing pool. We soon form a good union. We make a plan to take a trip to the waterfall tomorrow.`,
      ],
    },
  ],
  signature: '29/02/2024 - 02/03/2024'
}

export default post8
