<template>
  <div class="gallery">
    <template v-for="(pair, pairIndex) in imagePairs(images)" :key="pairIndex">
      <div class="gallery-row">
        <template v-for="(image, index) in pair" :key="index">
          <div class="gallery-image-container">

            <div class="gallery-image-source-container">
              <img :src="image.src" class="gallery-image" />
            </div>

            <!-- Black fade layer with white description text, centered -->
            <div class="fade-layer" @click="emitImageClick(image)">
              <div class="description-text">
                <div>{{ image.description[0] }}</div>
                <div v-if="image.description[1]">~</div>
                <div>{{ image.description[1] }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Gallery',
  props: {
    images: Array,
  },
  data() {
    return {
      positions: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
    }
  },
  methods: {
    imagePairs(images) {
      const pairs = []

      for (let i = 0; i < images.length; i += 2) {
        const pair = []
        for (let j = 0; j < 2; j++) {
          if (images[i + j]) {
            const image = { ...images[i + j] }
            pair.push(image)
          }
        }
        pairs.push(pair)
      }
      return pairs
    },
    emitImageClick(img) {
      this.$emit('image-click', img)
    }
  },
}
</script>

<style scoped lang="scss">
@import '../main.scss';

.gallery {
  width: 100%;
  margin: 80px 0;

  @media only screen and (max-width: 1000px) {
    margin: 40px 0;
  }
  @media only screen and (max-width: 500px) {
    margin: 30px 0;
  }
}

.gallery-row {
  margin: 50px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4%;

  @media only screen and (max-width: 1000px) {
    margin: 40px 0;
  }
  @media only screen and (max-width: 500px) {
    margin: 30px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
}

.gallery-image-container {
  position: relative;
  width: 36.5%;

  @media only screen and (max-width: 500px) {
    width: 70%;
  }
}

.gallery-image-source-container {
  z-index: 30;
}

.gallery-image {
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1;
  z-index: 30;
}

.fade-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.6s ease;
  z-index: 40;
}

.gallery-image-container:hover .fade-layer {
  opacity: 1;
  cursor: pointer;
}

.description-text {
  width: 50%;
  color: white;
  font-family: 'RozhaOne';
  text-align: center;
  font-size: 1.3vw;

  @media only screen and (max-width: 1000px) {
    font-size: 1.7vw;
  }
  @media only screen and (max-width: 500px) {
    font-size: 3vw;
  }
}

</style>
