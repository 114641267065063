import { PostProps } from "@/types"

const post19: PostProps = {
  key: 19,
  journeyKey: 0,
  x: '72.5%',
  y: '70.5%',
  location: 'Antalya',
  title: 'Walking, Yoga and walking again',
  content: [
    {
      type: 'text',
      value: [
        `Thursday afternoon, I was excited for a sunset walk in the old city. We met up at Hadrian's Gate, which is just 3 minutes from the hostel. The gate was preserved thanks to being hidden within the wall for many years, until a little bit of the wall fell off and someone noticed something majestic was sticking out. The gate depicts floral reliefs - unique flowers from Antalya, each looking completely different.`,
        `We walked through the cute little historical streets. The Ottoman-style houses have rooms sticking out of the house, kind of like a balcony, but then it is a whole room. Apparently, this is because people used to pay tax on the square meters that were measured on the ground - and this is how they could extend their living space without having to pay extra.`,
        `The city is extremely strict about preserving its history. When you buy a building or a land, you cannot do just anything you wish with it, you need to play into the Ottoman historical style. And if it happens by accident that you discover an archaeological site during reconstruction, you can do your best to conceal it - or the city takes hold of your property.`,
        `This way, the town really seems untouched - and almost like you are taking a walk into the past.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post19/15.jpg',
          description: ['vibrant houses all around'],
        },
        {
          src: '/images/00_balkans/post19/16.jpg',
          description: ['old town with preserved ottoman buildings'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `Me and another girl from the hostel were already complaining that we didn't see any trash cans - and this was demystified by our guide. Apparently, this is due to fire safety - otherwise, on a really hot summer day, having trash cans would be too hazardous. Another interesting detail is the city’s taxi system. On the streets, there are poles with taxi buttons you can just press and a taxi comes. The taxi companies each have their own district they take care of.`,
        `After seeing a beautiful sunset with the view over the sea, we moved onto the market. There, we were given some Turkish tea - but not the çay (black tea) but something that comes in the form of a very strong colored powder - each tea having a different color of the rainbow - and you just add this to hot water and stir. Our first impression was that this was really sugary and we shared this information in secret. Right after, we were told just how natural and healthy and sugar-free these teas are.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post19/1.jpg',
          description: ['nice door in the old town'],
        },
        {
          src: '/images/00_balkans/post19/2.jpg',
          description: ['more amazing street art'],
        },
        {
          src: '/images/00_balkans/post19/3.jpg',
          description: ['another viewpoint with the sea and the mountains'],
        },
        {
          src: '/images/00_balkans/post19/4.jpg',
          description: ['the buildings are at a risk of collapsing due to landslides'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `The next day, I spent my afternoon working hours in one of the cafes we were recommended by our tour guide. This one was made by travelers - for travelers. Varuna Gezgin. They started this cafe to finance their travels and visited every country in the whole world. The walls of the cafe are completely covered by pictures from the travels. The library is completely stacked with tens of thousands of books from all over the world. You see some cool retro artifacts collected along the way. And the ceiling is also completely decorated with flags. I feel such a solidarity and connection when I see places like these - ready to accept and welcome anyone. The cafe was also very welcoming to remote workers or digital nomads. Oh and a cool detail: the workers in the cafe are given a vacation every month to travel somewhere.`,
        `In the evening, I joined a group of people from the hostel to practice some kundalini yoga in the park. I learned a lot and enjoyed the practice, but I think it was still a bit much for me after being sick, and my legs were shaking on the way back to the hostel. I made sure to relax the rest of the evening, so that I am ready to explore all day the next day - since that’s Saturday.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post19/5.jpg',
          description: ['Varuna Gezgin - my new favorite place for working or just observing the interior'],
        },
        {
          src: '/images/00_balkans/post19/6.jpg',
          description: ['the flags from all over the world', 'can you spot slovakia?'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `In the morning, I woke up with very tired legs. I had signed up for a nature walking tour - or more like riding tour - the guide had a car and was supposed to drive us around to amazing nature places. I rushed through my breakfast to make it in time. I walked to the meeting point and kept circling around searching for ‘a very blue car’ from the description. I texted the guide only to find out he cancelled the tour because I was the only one who had registered. I should’ve read the description better, then I would have understood that the tour is only happening once it is confirmed.`,
        `I was a bit confused. I returned back to the hostel and brainstormed what I could do instead. I knew the city still had more culture to offer. The tour guide from Thursday sent us a whole list of mosques and museums we could visit for free.`,
        `I then made my way out of the hostel one more time. The town was still sleeping, it was now maybe 9 o’clock. I have not seen it this calm before. It was warm and sunny, like usual, but now it felt extra peaceful. I visited some stores that had already opened, offering nice cotton hippie clothing, and handcrafted jewelry.`,
        `Once it opened, I visited the ethnographic museum. This gave me a good idea of how Ottomans used to dress, how the house interiors used to look, and what kind of pottery they made. The museum was situated in a beautiful garden and from there, you could walk into another house with more artefacts, but also a cemetery.`,
        `The cemeteries used to be built in the public eye, in the center of happening, made to be seen by people every day. The ‘more important’ people had the bigger tombstones, with more decorations and writing. Women usually had floral decorations on the head of the tomb and a necklace on the ‘neck’. Men had hats and turbans. The identity of people was disclosed mostly by symbols on the graves, some indicating a profession, others telling a story: a flower meant that a person died at a young age, palm tree that the person was a pilgrim and a rope that a person hung themselves. After reading these details, it was really interesting to observe the tombs and to try to find stories in them.`,
        `The most interesting museum was the Dervish museum. Dervish is a person belonging to the Sufi religious group, which is a part of Islam, although focusing on very mystical concepts of spirituality and purification. They are easily recognized by wearing a cylindrical-kinda hat and long dress. They are known mostly for this spinning dance to a very calming beautiful and mysterious music. The spinning motion is supposed to bring them to a trance which makes them feel closer to god.`,
        `The museum was basically a building which used to serve this religious group. There was a lodge, a bath and some tombs. Inside the lodge, the music Sufi danced to was playing. The place was decorated with carpets and pillows and it made it look very authentic. You could walk around freely. As I sat down on one of the pillows, I tried to imagine the spinning ceremony. The whole building was breathing with this mysterious atmosphere, like I could almost see them spinning there.`,
        `Next, I went to the Broken minaret mosque. The minaret is no longer broken and the mosque is not a ruin - now it is beautifully reconstructed. This is more of a folk name and even after the reconstruction, people still call it the Broken minaret. I managed to go inside the mosque during the prayer time. I really loved the combination of the light yellow brick walls and blue carpets.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post19/11.jpg',
          description: ['the reconstructed `broken minaret` mosque', 'big'],
        },
        {
          src: '/images/00_balkans/post19/12.jpg',
          description: ['inside of the Sufi temple'],
        },
        {
          src: '/images/00_balkans/post19/13.jpg',
          description: ['the `broken minaret` mosque from the inside'],
        },
        {
          src: '/images/00_balkans/post19/14.jpg',
          description: ['a random non-tourist mosque'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `During the day, I saw two other mosques, Yivli Minaret Mosque, which has a very iconic minaret, it is dark brick and it has 8 cylindrical decorating structures around it. Inside, you could see some original archaeological findings under a glass floor.`,
        `Another mosque was a really small one, not really interesting, but that is what I wanted to see too - where the locals go to. I did not spend much time here because I felt out of place and was looked at kind of strange.`,
        `Somehow, after this crazy trip, I still managed to go to the beach and read my book. Before returning to the hostel, I ate some ‘liquid halva’. My perfect discovering day was complete.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post19/7.jpg',
          description: ['the garden of the etnographic museum'],
        },
        {
          src: '/images/00_balkans/post19/8.jpg',
          description: ['islamic cemetery at the etnographic museum'],
        },
        {
          src: '/images/00_balkans/post19/9.jpg',
          description: ['a part of the preserved ruin'],
        },
        {
          src: '/images/00_balkans/post19/10.jpg',
          description: ['Yivli Minaret'],
        },
      ]
    },
  ],
  signature: '12/10/2023 - 14/10/2023'
}

export default post19