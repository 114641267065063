
import { PostProps } from "@/types"

const post7: PostProps = {
  key: 7,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Chiang Rai',
  title: 'The Blue Temple and the white temple',
  content: [ 
    {
      type: 'text',
      value: [
        `Since my hotel is right by the station, I wake up at 6:45 to catch a bus at 7. It takes skill to get ready this fast. On the bus, I manage to do a bit of work. I arrive in Chiang Rai, which isn’t a particularly interesting town in itself, but it’s home to two of the most beautiful and unique temples: the White Temple and the Blue Temple. They are relatively new, thus not necessarily too authentic, but artistically very special. I check into my beautiful guesthouse, and even though I am feeling tired, I book a Grab to the Blue Temple. Wow, the motorbike drivers go fast here! I find myself clenching my jaw tightly.`,
        `The Blue Temple is, unsurprisingly, very blue - and so are all the surrounding buildings. I have a spiritual moment while in the temple - until a radio announcement reminds visitors to remove their shoes and store them in the drawers by the entrance. This announcement is repeated every minute in every language, which eventually ends my adoration for the blue temple and I move on.`,
        `I stop at the Doi Chang cafe for some more work. I learn that Doi Chang is a coffee with an inspiring backstory. King Rama IX launched the initiative to encourage the villagers of northern Thailand to move away from growing opium and transition into growing coffee beans. They now grow a large produce, all while keeping everything sustainable and supporting the natural habitat, a giant forest surrounding the farms. This initiative claims to also have united different hill tribes.`,
        `After coming home, I order a pizza (suddenly I crave western food), and work for the rest of the night.`
      ],
    },
    {
      type: 'images', // homestay, town, 2x blue temple, chiang rai
      value: [
        {
          src: '/images/01_southeast_asia/post7/1.jpg',
          description: ['Wat Chedi Luang at sunrise'],
        },
        {
          src: '/images/01_southeast_asia/post7/2.jpg',
          description: ['kayaking and chill out'],
        },
        {
          src: '/images/01_southeast_asia/post7/3.jpg',
          description: ['the stop at a market'],
        },
        {
          src: '/images/01_southeast_asia/post7/4.jpg',
          description: ['the famous night market'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `I did not sleep well, and I woke up early. I feel overwhelmed and overstimulated. I think all the constant moving around has taken a toll on me. And all this after getting sick in Pai. This morning, I plan to visit the White temple. I feel a tendency to be tired, angry, annoyed - a victim mentality. I bet I will be super tired. Yes, I will feel bad today.`,
        `But what if I do not have to? I decide I am going to challenge that. Despite the naggy thoughts that come up in my mind, I try to focus on what is good while I walk to the bus. The bus is really funky, very old and colourful, with fans all around. The seats are small and there is not much space. I get off at our stop and start talking to an older lady about which way is the temple.`,
        `A young woman joins us and all three of us walk to the temple and buy a ticket. The older woman leaves but me and the other girl stick together. Seems like her ‘job’ is done here - to connect two new friends.`,
        `The girl’s name is Diana. This is a good sign, my best friend from my home is also called Diana. She exudes a very cheerful and happy vibe. Maybe if I had not decided to shift my mood this morning, I would not have met her.`,
        `We decide to hang out the whole time, exploring the temple together. It is really beautiful, a real photographic heaven. We buy another ticket into a (fake) cave with lights, music and statues inside. Later we check out an art gallery, which again has a lot of art with strong Asian symbols.`,
      ]
    },
    {
      type: 'images', // 4x white temple
      value: [
        {
          src: '/images/01_southeast_asia/post7/5.jpg',
          description: ['the temple with a nice reflection'],
        },
        {
          src: '/images/01_southeast_asia/post7/8.jpg',
          description: ['the famous night market'],
        },
        {
          src: '/images/01_southeast_asia/post7/10.jpg',
          description: ['the strange art we saw at the white temple'],
        },
        {
          src: '/images/01_southeast_asia/post7/11.jpg',
          description: ['brunch with Di at the Japanese cafe'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `We are there at the right time, because there are not many tourists yet and the weather is pleasant. Diana and I show off more of our personalities the more we spend time together, and once we are back on the bus, we are both quite connected, laughing and sharing funny and personal stories. We decide to have brunch together in a very nice Japanese place. I learn that Diana is a graphic designer from Peru and works more in the late night hours. I admire this, also that she managed to wake up so early for the temple.`,
        `We say goodbye when I leave to start working. I am leaving her all cheered up, happy that refocusing your mood really can have a good effect on your day.`
      ],
    },
  ],
  signature: '27/02/2024 - 28/02/2024'
}

export default post7
