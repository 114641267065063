import { PostProps } from "@/types"

const post18: PostProps = {
  key: 18,
  journeyKey: 0,
  x: '70%',
  y: '68%',
  zIndex: 110,
  location: 'Antalya',
  title: 'Staying at the Be Bold hostel, the easiest way to socialize',
  content: [
    {
      type: 'text',
      value: [
        `The first day in Antalya was slightly clumsy. I arrived in the morning, but it was late enough to go straight to Starbucks. I made myself look like a human again and I enjoyed some coffee while I got some work done.`,
        `In the afternoon, I went to the hostel and took a really big nap there. When I woke up, I felt like I was in a different century and on a different planet. I went to take a shower and then I went to eat out. I ordered a nice pizza, but while waiting, I realized I am feeling pretty nauseous. I felt like I would puke pretty soon, so I asked them to pack my pizza in a box and walked to a park. Here, I felt okay enough to puke if I needed to. It is interesting that I was both nauseous and hungry - and I managed to finish the pizza even.`,
        `What caused all of this is unknown, but I am not someone who would suddenly feel physically nauseous for no reason. I was later told by someone that some vegetables in Turkey were contaminated by something in the past few days, and I ate fast food last night. But another suspicion is that I was silly enough to drink tap water which I trusted was drinkable.`,
        `I went to sleep with a plastic bag by my side.`,
      ]
    },
    {
      type: 'text',
      value: [
        `The next morning, I felt slightly better. I worked in the beautiful hostel garden and was joined by some other hardworking, very nice people. This seems to be the hostel where people often go while working remotely. Even the garden tables had power sockets. So I always stayed till about noon, until it got unbearably hot.`,
        `In the afternoon, I was pretty excited to go to the beach. I have not swam in a long time. I walked through the beautiful town, called up a friend. Then I got on the beach, swam, meditated and read a book - 'A New Earth' by Eckhart Tolle - I recommend. Eventually, I also called my family and as it was slowly getting dark, I decided to walk back. The sun sets one hour earlier here compared to Istanbul.`,
        `In the hostel, I made a friend. A girl from Finland who works in London but mostly works remotely and travels. With this girl, I had the most deep and long conversation about this experience. I listened to her talking about her experiences in South-East Asia, and how that is the perfect place for such travels. She said they had amazing wifi and she really liked the timezone - for her, that meant she explored around in the morning, then worked in the afternoon and went to sleep after work.`,
      ]
    },
    {
      type: 'text',
      value: [
        `I should probably explain how this hostel (called Be Bold Hostel) is different from others. First of all, it is by far the most fancy one I stayed at. It has a really well-equipped kitchen - to satisfy all your cooking wishes. It has a nice long table for eating, working, and socializing. The garden I have mentioned is rather big, there are many places to work or relax. And there are awesome palm trees everywhere. I could even enter my hostel room straight from the garden. The best thing is the location, a minute away from the gate which enters into the old town.`,
        `Another wonderful thing is that there are three pets. A dog, a cat, and a tiny little kitten, the smallest one I’ve seen. The kitten was especially taken care of by all the guests from the hostel, because it seemed to need some human warmth and protection. It was too small to be away from its mommy. I heard it fell from a wall into the garden and the hostel adopted her.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post18/1.jpg',
          description: ['my favorite spot for working:', 'the garden of the hostel'],
        },
        {
          src: '/images/00_balkans/post18/2.jpg',
          description: ['our tiny hostel friend'],
        },
        {
          src: '/images/00_balkans/post18/3.jpg',
          description: ['Hadrian`s gate'],
        },
        {
          src: '/images/00_balkans/post18/4.jpg',
          description: ['the view on the mountains and the sea'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `However, the main difference between this hostel and other ones I stayed at is that this one has a weekly program. The volunteers basically make a program for their guests, everyone is welcome to join. I found this interesting. I would say that hostel people always find fun activities and people to do them with. But for me this way of planning things was really fitting for now. I was still recovering from being sick and now also from nausea. I wanted something easy, where I can just join and everything is already laid out and planned days ahead. In a way, it felt like a summer camp and the volunteers felt like summer camp tutors. But for adults.`,
        `So today, we went to have a picnic at the beach together. The next day, I joined the food market, where we hopped to different locations and tasted small plates of traditional Turkish food. My most liked one was Piyaz. This was a stew with dried white beans and it tasted amazing. We were at a place a bit further from the city center and it looked really authentic. It was also really cheap. Since we wanted to share small portions amongst all of us (maybe 8 people), we ordered 2 of those only and asked for extra plates. The waitress was not entertained.`,
        `When we got back, we sat comfortably in the garden, ate popcorn, and watched the movie Memento on a big projector screen. That was the perfect end to the day.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post18/5.jpg',
          description: ['very spicy peppers and some Piyaz in the background'],
        },
        {
          src: '/images/00_balkans/post18/6.jpg',
          description: ['movie night!'],
        },
        {
          src: '/images/00_balkans/post18/7.jpg',
          description: ['Hidirlik tower'],
        },
        {
          src: '/images/00_balkans/post18/8.jpg',
          description: ['the view after the sunset'],
        },
      ]
    },
  ],
  signature: '08/10/2023 - 11/10/2023'
}

export default post18