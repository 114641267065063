import post0 from '@/posts/01_southeast_asia/00_bangkok_1'
import post1 from '@/posts/01_southeast_asia/01_bangkok_2'
import post2 from '@/posts/01_southeast_asia/02_bangkok_3'
import post3 from '@/posts/01_southeast_asia/03_chiang_mai_1'
import post4 from '@/posts/01_southeast_asia/04_chiang_mai_2'
import post5 from '@/posts/01_southeast_asia/05_chiang_mai_3'
import post6 from '@/posts/01_southeast_asia/06_pai'
import post7 from '@/posts/01_southeast_asia/07_chiang_rai'
import post8 from '@/posts/01_southeast_asia/08_mekong'

const postsSoutheastAsia = [
  { name: 'Bangkok 1', path: 'bangkok-1', data: post0 },
  { name: 'Bangkok 2', path: 'bangkok-2', data: post1 },
  { name: 'Bangkok 3', path: 'bangkok-3', data: post2 },
  { name: 'Chiang Mai 1', path: 'chiang-mai-1', data: post3 },
  { name: 'Chiang Mai 2', path: 'chiang-mai-2', data: post4 },
  { name: 'Chiang Mai 3', path: 'chiang-mai-3', data: post5 },
  { name: 'Pai', path: 'pai', data: post6 },
  { name: 'Chiang Rai', path: 'chiang-rai', data: post7 },
  { name: 'Mekong', path: 'mekong', data: post8 },
]

export default postsSoutheastAsia
