<template>
  <transition name="modal-fade">
    <div class="modal-overlay" v-if="isVisible" @click.self="close">
      <div class="modal">
        <div class="modal-content">
          <div class="title">
            <div class="title-text">andrea goes...</div>
          </div>

          <div class="subtitle">
            <hr class="subtitle-divider">
            <div class="subtitle-text">{{ viewMode }}</div>
            <hr class="subtitle-divider">
          </div>
          
          <div v-if="viewMode==='map'">
            <Map
              :posts="posts"
              :selectedPostIndex="selectedPostIndex"
              @go-to-post="goToPost"
            />
          </div>

          <div v-else>
            <About />
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Map from './Map.vue'
import About from './About.vue'

export default {
  name: 'ModalNavigation',
  components: {
    Map,
    About
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    posts () {
      return this.$store.state.posts
    },
    selectedPostIndex () {
      return this.$store.state.selectedPostIndex
    },
    viewMode () {
      return this.$store.state.viewMode
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    changeViewMode (newViewMode) {
      this.$store.commit('SET_VIEW_MODE', newViewMode)
    },
    isSelected (viewMode) {
      return this.viewMode === viewMode
    },
    goToPost (index) {
      this.$store.dispatch('goToPost', index)
      this.close()
    }
  },
}
</script>

<style scoped lang="scss">
@import '../main.scss';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 25, 25, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.5s ease;
}

.modal {
  position: relative;
  background-color: $black;
  border-radius: 14px;
  width: 60vw;
  transform: scale(0.9);
  opacity: 0;
  box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.5);
  transition: transform 0.5s ease, opacity 0.5s ease;

  @media only screen and (max-width: 1000px) {
    width: 80vw;
  }
  @media only screen and (max-width: 500px) {
    width: 95vw;
  }
}

.modal-content {
  padding: 30px 50px;

  @media only screen and (max-width: 1000px) {
    padding: 20px 10px;
  }
  @media only screen and (max-width: 500px) {
    padding: 20px 10px;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'RozhaOne';
  color: $cappuccino;
  margin-bottom: 30px;

  @media only screen and (max-width: 1000px) {
    padding: 0 10px;
    margin-bottom: 10px;
  }
}

.title-text {
  font-size: 40px;

  @media only screen and (max-width: 1000px) {
    font-size: 30px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 24px;
  }

  -webkit-background-clip: text !important; /* For WebKit browsers */
  background-clip: text !important; /* For other browsers */
  color: transparent !important;
}

.title-buttons {
  height: 100%;
  display: flex;
  gap: 10px;
  font-size: 20px;

  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }
}

.title-buttons > * {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.title-buttons > *:hover, .selected {
  opacity: 0.7;
}

.subtitle {
  display: flex;
  align-items: center;
  font-family: 'RozhaOne';
  color: white;
  margin-bottom: 30px;

  @media only screen and (max-width: 1000px) {
    padding: 0 10px;
    margin-bottom: 10px;
  }
}

.subtitle-text {
  width: 45px;
  font-size: 20px;
  margin-right: 20px;
  margin-left: 20px;

  @media only screen and (max-width: 500px) {
    width: 30px;
    font-size: 14px;
  }
}

.subtitle-divider {
  flex-grow: 1;
  border: 1px solid white;;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-to,
.modal-fade-leave-from {
  opacity: 1;
}

.modal-overlay .modal {
  opacity: 1;
  transform: scale(1);
}

.modal-fade-enter-active .modal,
.modal-fade-leave-active .modal {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.modal-fade-enter-from .modal,
.modal-fade-leave-to .modal {
  opacity: 0;
  transform: scale(0.9);
}

.modal-fade-enter-to .modal,
.modal-fade-leave-from .modal {
  opacity: 1;
  transform: scale(1);
}
</style>
