
import { PostProps } from "@/types"

const post5: PostProps = {
  key: 5,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Chiang Mai',
  title: 'Cafes, a hiking trip and a goodbye',
  content: [ 
    {
      type: 'text',
      value: [
        `Duman and I pick up breakfast from 7-eleven and enjoy some amazing coffee from a beautiful place called Akha Ama Phrasingh.`,
        `At noon, we finally manage to get a massage - Duman goes for a regular Thai massage and I try out the tok sen hammer and chisel therapy. This is literally a massage therapist hammering a chisel on your muscles, making it feel like he separates individual tissues. This gave me a strange ticklish/tingly and slightly uncomfortable sensation, but somehow also made me feel zen. The place where we are taking the massage is a temple 3 minutes from the hostel and the massage costs 120 Baht (about 3 euros)!`,
        `After this, we go to Kalm, which is a beautiful, beautiful art place, ahhh. I am speechless. There are many many wonderful contemporary paintings, some statues, a crafty shop, places for workshops, and a cafe. It is a very big, wooden, cottage-like, spacious building, and every corner is absolutely cute. The paintings have hints on Asian culture, which I love. I think until now I didn't realize, but western art is western. And Asian art, well, has a lot of Asian culture built into it. It is very interesting to observe the culture through art like this.`,
        `We settle in the café and I try my first orange espresso - seriously, who hasn't tried this yet has to prioritize it. Lisa soon joins us, and we take this time to go through every room in the building.`,
        `We eat lunch at the Vegan society restaurant. I am thinking just how much Chiang Mai feels like the exact right place for me. We have a very big filling lunch and great conversations. I have some Khao Soi (a traditional north-Thai soup), spring rolls and a chocolate-banana smoothie. We feel very happy and satisfied.`,
        `In the evening, I say goodbye to Duman at a smoothie place where he introduces me to a very sweet woman and amazing smoothies. One regular client leaves and brings another future regular client. We talk about when saying goodbye, whoever is the one to walk away has it easier. I make sure to not stand sad while his taxi picks him up, but to leave at the same time, appreciating all the amazing experiences and talks we had.`
      ],
    },
    {
      type: 'images', // kalm
      value: [
        {
          src: '/images/01_southeast_asia/post5/0.jpg',
          description: ['the atrium in Kalm'],
        },
        {
          src: '/images/01_southeast_asia/post5/1.jpg',
          description: ['their impressive gallery'],
        },
        {
          src: '/images/01_southeast_asia/post5/2.jpg',
          description: ['the kitchen (perhaps for cooking classes)'],
        },
        {
          src: '/images/01_southeast_asia/post5/3.jpg',
          description: ['an artpiece that left an impression'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `Duman and I pick up breakfast from 7-eleven and enjoy some amazing coffee from a beautiful place called Akha Ama Phrasingh.`,
        `At noon, we finally manage to get a massage - Duman goes for a regular Thai massage and I try out the tok sen hammer and chisel therapy. This is literally a massage therapist hammering a chisel on your muscles, making it feel like he separates individual tissues. This gave me a strange ticklish/tingly and slightly uncomfortable sensation, but somehow also made me feel zen. The place where we are taking the massage is a temple 3 minutes from the hostel and the massage costs 120 Baht (about 3 euros)!`,
        `After this, we go to Kalm, which is a beautiful, beautiful art place, ahhh. I am speechless. There are many many wonderful contemporary paintings, some statues, a crafty shop, places for workshops, and a cafe. It is a very big, wooden, cottage-like, spacious building, and every corner is absolutely cute. The paintings have hints on Asian culture, which I love. I think until now I didn't realize, but western art is western. And Asian art, well, has a lot of Asian culture built into it. It is very interesting to observe the culture through art like this.`,
        `We settle in the café and I try my first orange espresso - seriously, who hasn't tried this yet has to prioritize it. Lisa soon joins us, and we take this time to go through every room in the building.`,
        `We eat lunch at the Vegan society restaurant. I am thinking just how much Chiang Mai feels like the exact right place for me. We have a very big filling lunch and great conversations. I have some Khao Soi (a traditional north-Thai soup), spring rolls and a chocolate-banana smoothie. We feel very happy and satisfied.`,
        `In the evening, I say goodbye to Duman at a smoothie place where he introduces me to a very sweet woman and amazing smoothies. One regular client leaves and brings another future regular client. We talk about when saying goodbye, whoever is the one to walk away has it easier. I make sure to not stand sad while his taxi picks him up, but to leave at the same time, appreciating all the amazing experiences and talks we had.`
      ],
    },
    {
      type: 'text',
      value: [
        `At breakfast, I have a quick talk with Puck before I catch my first motorbike Grab taxi to the start of today’s hike trail. I start walking upwards the same way as we did with Duman and Rutvik on our little hike together. I can see a lot of colourful buddhist woven hanging flags on trees along the trail. Today this is probably just another one of many buddhist festivities.`,
        `At some point, I see a detour to the Wat Anagami, which allows me to see a new place instead of hiking the same way as last time. This is an intense ascend. I am the only one taking it; I feel completely alone in the jungle.`,
        `Wat Anagami is an abandoned temple, and that makes me sad. It is a bit more modern. I like it. It has light green pastel colored tiles, blue pillars with some stone decorations and a metal rooftop. Every now and then, I hear a loud rooster. The temple is in a close proximity to a little village/community in the mountains.`,
        `In this little village, I find a forest fire control center and read a little bit about their influence on keeping the forest safe and healthy. I look at the map and wonder how far in the mountains I will get today. I can buy some coffee and use the toilet.`,
        `The next part of the hike I have to endure walking on a big road. Suddenly, the forest is not so peaceful anymore. The loud noise of motorbikes and large tuktuks overpowers the sound of crickets and birds. Now I realize how many foreigners are in Chiang Mai - almost every person I see on a motorbike is clearly a foreigner. The road is very steep and curvy, but I try to stay as safe as possible while vehicles pass by.`,
        `Soon I find a trail again in the forest. Here, I have to take another steep ascend but I feel more ready for it. I am almost surprised when I arrive at Doi Suthep within about 30 minutes.`,
        `The entrance to the complex is surrounded by many shops and restaurants. To get to the toilet, I have to get through what might be the biggest souvenir store I’ve ever been to - a giant maze of Thai-buddhist artifacts.`,
        `When I am all refreshed and comfortable, I start walking up the famous long decorated staircase up to the temples. On top, I remove my shoes and walk around every temple. I can see a lot of tourists but even more religious people praying devotedly at the temples.There are a lot of monks who probably come from all over Thailand and possibly other buddhist countries. I find the first female buddhist statue and celebrate inside. After making sure to look at all the temples and sit at some in peace, I check out the panoramic view from the complex.`,
        `Later, I notice arrows to a meditation center and I walk down into the forest. Here, I see cottages with orange Buddhist clothes hanging on ropes. I think this must be where the monk communities stay while on their pilgrimage. I keep going more and more down in a little maze following the sign, very well aware that I am going to have to walk it back up. I finally find the meditation centre and suddenly there is absolute peace in comparison to the crowded temples. There is no one here apart from me. I prefer to meditate here - and I only hope I am allowed to be here.`,
        `I return to the famous staircase, indecisive what to do next. I consult the map and realize any of the points of interest are too far to hike. I talk to a very kind taxi driver and show interest in going to the Hmong Doi Pui village. We wait for about 45 minutes to see whether any other people join. Then I give up and pay the 500 Baht taxi just for myself.`,
        `I love these taxi rides, there are no windows on the big tuktuks, so I get to enjoy the wind and I can watch the road curving behind us, occasionally spying on the vehicles around, or just watching the landscape. This is a very hilly area and sometimes I get the chills in the more narrow parts of the road, where we have to share it with other cars going in the opposite direction.`,
      ]
    },
    {
      type: 'images', // hike + village
      value: [
        // {
        //   src: '/images/01_southeast_asia/post5/4.jpg',
        //   description: ['Wat Chedi Luang at sunrise'],
        // },
        // {
        //   src: '/images/01_southeast_asia/post5/5.jpg',
        //   description: ['kayaking and chill out'],
        // },
        {
          src: '/images/01_southeast_asia/post5/6.jpg',
          description: ['the staircase to Doi Suthep'],
        },
        {
          src: '/images/01_southeast_asia/post5/7.jpg',
          description: ['a meditating woman'],
        },
        {
          src: '/images/01_southeast_asia/post5/8.jpg',
          description: ['a cat at the garden in the Hmong village'],
        },
        {
          src: '/images/01_southeast_asia/post5/9.jpg',
          description: ['the view over the village in the mountains'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `With the driver, we make an agreement to meet in two hours to go back. I buy a ticket to the village for a symbolic price and enter. You have to get through small streets full of shops covered with tinkered rooftops to actually make it to the other side. The village itself is a little gardening community. The Hmong people are indigenous to this region, and they preserve their culture (as seen in their clothing and crafts). The garden is really like a fairytale. It's located on a cozy hill, with little pathways among the crops and flowers. I make friends with a cute cat.`,
        `Up on the hill, just above the garden is a big coffee place. Here, they brew their own coffee typical for northern Thailand. I peek into the room with a big machine which mixes the coffee.`,
        `From here, I notice a little viewpoint. I walk there and meditate on a bench before my time is up and I am ready to wave the village goodbye and meet up with my driver.`,
        `The driver is currently eating his dinner and immediately stops and jumps behind the steering wheel as he sees me (even though I told him I could wait). Something about him is so adorable, vulnerable, and deeply kind. I am not sure what is happening with me but it makes my eyes water and I feel a lot of gratitude. The taxi was 'expensive' but if this drive paid 5 of his dinners, I am very happy about it.`,
        `I got dropped off again at Doi Suthep, where I hiked down to Wat Pha Lat again. From this side, I stumble upon a cute waterfall, the nature being decorated with Buddha statues and other Buddhist symbols.`,
        `As I make it to the start of the trail, I get a headache. I catch a motorbike ride back home, shower, pick up a smoothie at 'Duman's' place and get a massage. It was bad but you cannot really complain as it really costs 3 euros per hour. I book another one for tomorrow.`
      ]
    },
    {
      type: 'text',
      value: [
        `In the morning, I say goodbye to Puck, who I will reunite with in the village of Pai in a few days. Now everyone is gone: Duman, Lisa, Puck. I am going to spend the last days in Chiang Mai mostly alone, just focusing on work.`,
        `Today, I manage to get some exercise, followed by a really good massage and lunch at my favourite vegetarian place. I end up working in the Kalm place, up in the library`,
        `The next day, I went to work at the Mars place. This is a futuristic space-themed cafe and it was really inspiring for work. After the lunch break, I walk to the Yellow coworking space. This is my first co-working experience. I paid 11 euros to be in a big building with coffee, computer screens and ventilators. First I stood at the standing desk before I moved to the fatboy sacks. It was an interesting workday, but it does get pricey unless you have a subscription.`,
        `In the evening, I started to feel a little bit sick. The next day I took a sick day and only managed to go to a really beautiful temple and to later pick up my laundry. Tomorrow is a big day, and I hope to recover before I take the van to Pai.`
      ],
    },
    {
      type: 'images', // caffes + temples
      value: [
        {
          src: '/images/01_southeast_asia/post5/10.jpg',
          description: ['Wat Chedi Luang at sunrise'],
        },
        {
          src: '/images/01_southeast_asia/post5/11.jpg',
          description: ['kayaking and chill out'],
        },
        {
          src: '/images/01_southeast_asia/post5/12.jpg',
          description: ['the stop at a market'],
        },
        {
          src: '/images/01_southeast_asia/post5/13.jpg',
          description: ['the famous night market'],
        },
      ],
    },
  ],
  signature: '17/02/2024 - 21/02/2024'
}

export default post5
