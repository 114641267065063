import { PostProps } from "@/types"

const post16: PostProps = {
  key: 16,
  journeyKey: 0,
  x: '60%',
  y: '14%',
  zIndex: 110,
  location: 'Istanbul',
  title: 'The mosques and the cistern',
  content: [
    {
      type: 'text',
      value: [
        `Today, I managed to collect some energy and went to see Hagia Sophia. On the way there, I first got drawn into the Blue Mosque, which I wanted to see at some point anyway. The mosque wasn't just blue, it was very colourful. It was ornamental and really playful. I have to say, I might prefer the Islamic architecture more than the Christian one. At least definitely from the inside. It doesn't matter which mosque I went into, they all had beautiful ornaments and colours all in a subtle balance. Nothing too crazy, although still very detailed.`,
        `The Blue Mosque was very playful and light, Hagia Sophia seemed more heavy and deep and dark. But all in a good way of course. Amongst the countless tiles, ornaments, and lights, I spotted a Christian mosaic on the side of the mosque, disclosing the Christian part of its history. It is forbidden to show faces and people in mosques, so the only other Christian mosaics or frescoes that were still in the main part of the mosque were covered by a white sheet. Here, I made sure to sit down for a bit and read some pamphlets about Islam, to learn more about the religion. After some time, we got kicked out of the main part, due to the prayer time. I was still allowed to go to the small female section, where I sat on the carpet with other women, who were waiting for the prayer - some were already praying. I felt a lot of peace in this place and situation. When the prayer started, I felt a bit out of place, so I left. I don't want to be an annoying tourist who disrespects their prayer time out of pure curiosity.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post16/2.jpg',
          description: ['windows and ornaments'],
        },
        {
          src: '/images/00_balkans/post16/1.jpg',
          description: ['the breathtaking Blue Mosque'],
        },
        {
          src: '/images/00_balkans/post16/3.jpg',
          description: ['the most memorable sight of Hagia Sophia', 'these are called `calligraphic roundels`'],
        },
        {
          src: '/images/00_balkans/post16/4.jpg',
          description: ['the mosque had a dark and mystical atmosphere'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `I ate dinner in an iconic old school restaurant. It used to be THE place for hippies and travellers in Istanbul in the 70s. It was called the Pudding Shop. Now it is quite a regular restaurant, but it was interesting to look through all the pictures from that time. After dinner, I got myself a box of Turkish delight and couldn't resist opening it and eating from it on my way home.`,
        `I walked through the Old bazaar. It was perhaps only 8 o'clock, but the stores were already closed. I was a bit surprised by this, I thought the market was buzzing till late hours. Now I saw the aftermath. There was trash, empty boxes, even some broken glass. Some city workers were cleaning up that stuff.`
      ]
    },
    {
      type: 'text',
      value: [
        `The next day, I again took it easy during the day but made sure to go see the Basilica Cistern in the evening. First, I got pulled into a restaurant. The amount of food was unbearably high, especially for a sick person. When I asked, I was told it was not a lot, so they convinced me to order it with a salad. I couldn't finish it, so I asked to have it packed to make it to the cistern in time. Spoiler alert: The food leaked in my bag.`,
        `I rushed to the cistern to make it in time. I was going for the night ticket, which was a bit more costly. Apparently, there are sometimes surprise concerts and performances during the night ticket hours.`,
        `The cistern was wonderful, really giant and surreal. And again, just like the mosques, a photographic paradise. Too bad I don't have anything better than my bad phone camera. I made sure to walk really slowly through and spent around an hour inside, but I haven't seen any performances - so the extra cost wasn't really worth it. However, I loved that there were nice statues every now and then. They kept changing the light colours because things looked a bit different under each colour. This was amazing.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post16/5.jpg',
          description: ['still the least blurry picture of myself I managed to get'],
        },
        {
          src: '/images/00_balkans/post16/6.jpg',
          description: ['the pillar which caught my interest the most'],
        },
        {
          src: '/images/00_balkans/post16/7.jpg',
          description: ['one of many beautiful art installations'],
        },
        {
          src: '/images/00_balkans/post16/8.jpg',
          description: ['the overall view'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `When I got back to the hostel, I met a woman from another room. This hostel is a place where everyone has their own room, and there is no common room, so it is rare to meet someone. But we made friends. Her name was Nathalie. When she heard I was sick, she gave me some essential oils.`
      ]
    },
  ],
  signature: '05/10/2023 - 06/10/2023'
}

export default post16