import { PostProps } from "@/types"

const post2: PostProps = {
  key: 2,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Bangkok',
  title: "E-bus, eagle's nest, meditation class, and Bangkokg's parks",
  content: [ 
    {
      type: 'text',
      value: [
        `Today, I am on my own. And I suffer the realities of my own stubbornness. I pledged to use the free bus which is a part of the Bangkok Design week. It takes you to many of the festival's locations. I do my best to make use of the bus but end up struggling to make it work. Instead of getting a taxi, I stubbornly walk around and wait in the crazy Bangkok heat.`,
        `For breakfast, I enjoy my very first mango sticky rice. This is such an insanely good culinary experience that it cannot be explained but has to be lived. Now is the time to figure out the timetable and route of the bus. No matter how hard I search, I only find quite a bad resolution map on a Facebook page. Apparently, if I arrive at the bus stop, I can scan the QR code and then see the live bus locations. I do my best to estimate the closest location and walk the 30 minutes. I open the map again and compare the street shapes to those of Google maps to find the precise street where I am supposed to be. However, I cannot see the bus stop where I would scan the QR code so I have no idea when the bus will be there. The buses should go about every 30 minutes, but one bus is out of service.`,
        `I kill time by a quick peek into a free museum. Then I walk back and forth on the street and when I am at the very end, I finally see the bus at the other end. I wave at it desperately and the bus driver waves back. I run down the street after the bus but the bus driver takes no interest in waiting for me, and I miss it. I feel humbled. When I make it back to the beginning of the street, I finally see the bus stop with the QR code and I give myself a big internal facepalm.`,
        `I scan the QR code and realize I have plenty of time before the second bus makes it to my location, so I go on a big odyssey to the next two or three stops ahead. Now I see the map with all the locations (including mine) perfectly. I appear in a strange, quiet Chinese residential quarter. There are many garages and craft studios. Now the heat is getting quite unbearable so I navigate to the closest Chinese restaurant. With some struggles, we find a vegetarian alternative with the waiter. It is a soup with seaweed, really hot and spicy, making me feel five degrees hotter.`,
        `After I leave the restaurant, I take a break in an air-conditioned 7-eleven store. Then I walk further to the next stop and seeing where the bus is currently, it seems I have to suddenly hurry. I make it in time and after 4 hours of trying, I finally enjoy my first ride in a cute comfortable e-bus.`,
        `I look out the window and realize life would be easier if I sometimes just gave up on an idea that no longer makes sense. I hear an AI voice announcing the stops, and I suddenly realize I don't even have a plan. I quickly take out my phone to find an attractive location but get pretty overwhelmed. I decide to get off the bus whenever multiple people do - sheep behavior. It turns out to be the stop for the national museum.`,
        `Here, there is no festival happening, but I am not complaining - I do want to learn more about Thai identity. After a nice educational round in a museum with my first ever audio guide, I make my way out to the bus stop. This time I am suddenly lucky and the bus arrives exactly when I do. I also manage to figure out my next location pretty easily - Yaowarat, in Chinatown.`,
        `Here, there are multiple things to see. I take a look at photography and art exhibitions, learn about ethnic coliving in Bangkok, and visit a school where Myanmar children learn Thai to get a chance at life in a safe country. I enjoy a vegan dinner at a shopping mall, then return to the Myanmar school to watch a photography screening created by the kids documenting a year of their life in Thailand.`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post2/1.jpg',
          description: ['the chinese quarter I accidentally found myself in'],
        },
        {
          src: '/images/01_southeast_asia/post2/2.jpg',
          description: ['inside the national Thai museum'],
        },
        {
          src: '/images/01_southeast_asia/post2/4.jpg',
          description: ['Yaowarat and its poor cable management'],
        },
        {
          src: '/images/01_southeast_asia/post2/6.jpg',
          description: ['a piece from an artist who paints mostly cool dragons'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `Before the screening, I talk to a guy who explains what the screening is about. He later takes the microphone and translates everything said at the event to English while making eye contact with me. I happen to be the only foreigner at the presentation. I know I have to catch my free bus soon, but I feel bad leaving mid-presentation, especially when someone makes a really kind effort to include me. I stretch it as far as I can, then I slowly stand up, show a gesture of gratitude to the translating man and leave. I run to the street and run for my life to barely make it to my bus.`,
        `I expected the bus to take a full round, thus bringing me to my area, but instead it stops at Talat Noi for a break. I have to wait 45 minutes for a new bus. I seriously consider ending my streak of free bus rides and finally taking a taxi.`,
        `What convinces me to wait is a concert at the design centre which I visited with Eif a few days ago. There is a really cool asian rock girl group and I can really vibe to the music. After the concert, I take one last peek at the market of all the amazing designs from the talented artists and then claim my seat on my last free bus ride with no regrets. I take a good night's rest before my working days begin.`
      ],
    },
    {
      type: 'text',
      value: [
        `Skipping to Tuesday. In the evening, I walk to the Eagle's nest. Here I am meeting up with Eif, whom I missed dearly since Friday, and Kathrina, her German friend whom she also met through the same group and took care of her here in Bangkok. I climb a staircase and make it into a really cool rooftop bar. I am taken aback by both the breathtaking view and the overcrowdedness of the place. I scan through the people to find the girls.`,
        `Here, I get to catch up with Eif and learn more about Kathrina, her work, her travel and her life. We share nice conversations, do a little photoshoot, observe the scenery under different lights until we see a night time Bangkok. The rooftop is magical.`,
        `After that, we take the metro - my first one. I am entertained by a board saying 'No durian allowed in the metro'. It must indeed smell really bad.`,
        `We appear in the big busy loud Chinatown. We stop at many stands, sharing little snacks here and there. Rotti, soup, fried mushrooms, mango sticky rice, coconut ice cream. We fall into a real food coma. I am so full I can barely move at this point. How lucky we are that Eif brings us home again.`
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post2/7.jpg',
          description: ['the sun setting down at the Eagle\'s nest'],
        },
        {
          src: '/images/01_southeast_asia/post2/8.jpg',
          description: ['what a view!'],
        },
        {
          src: '/images/01_southeast_asia/post2/9.jpg',
          description: ['the busy and hot chinatown'],
        },
        {
          src: '/images/01_southeast_asia/post2/10.jpg',
          description: ['girls convinced me to try some coconut ice cream'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `On Thursday, I find my way through the complicated temple grounds to find my meditation class. I enter a long, narrow room with a very low ceiling, ventilated with a fan. I take a mat and sit with the other pupils. We are facing the monk sitting in a cross-legged position on a little pedestal. He engages us in a conversation. I recognize his English is not that good, but he does an insanely good job at conveying a message. It could be the way he searches for metaphors, uses simple words and repeats his message in many different ways many times until we get it. Until it is burnt into our brains.`,
        `We start off with a little conversation about why we are here. I see that the other pupils are really curious and attentive and keen to learn. I love being in such environments. As for my reason, I say: 'I want to be more present and aware'.`,
        `We start our sitting meditation without much guidance first. I enjoy the meditation, although I notice I can only remain focused for about a few seconds. Since it is a sitting meditation, my back starts to hurt too. I start wondering how much time has passed. In my estimate, it takes about 20 minutes until the monk softly breaks the silence.`,
        `'What do you do when a thought emerges'? Asks the monk. He provides an answer that tops all our guesses.`,
        `'You say: I know. You acknowledge the thought and the thought goes away. There is no need to elaborate the thought. It's like your thought is a mouse and saying I know is the mouse. The cat chases the mouse away.'`,
        `'During meditation, only four things are true', he continues, 'you sit, you breathe, you feel and you hear'. He goes on to explain about how everything that does not have to do with simply being, is a construct. When we think about the past, the future, those are not real things. While meditating, only being in the now exists.`,
        `With this knowledge, we dive into another seating meditation and try to apply what we learned.`,
        `The lesson continues on with a walking meditation, which I personally did not like as much. The point of this meditation is to walk extremely slow while repeatedly chanting what you're doing at every moment: 'Staaanding. Intending to walk. Right foot up, sliding, right foot down'. To me, a sitting meditation with no chanting removes the distractions better and brings me easier into the state of peaceful being. However, I'm not saying I won't give it a chance. What I will for sure not give a chance anymore is the standing meditation. Here you chant with every breath: 'Staaanding. Staaanding. Staaanding…' This one left me completely breathless.`,
        `We finally sit down to receive more wisdom from the monk. Some people express their interest to learn more about anger management. He mentions three techniques. 1: 'They are imperfect just like I am’. 2: ‘You can see the other person as a teacher and thank them for the teaching.' The third one is my favorite: 'Imagine you are a river. The river never tells the fish: do not swim in me!'`,
        `What surprised me the most was learning about the real path to Nirvana (when you become enlightened, thus you end the cycles of being reborn again). Apparently, there are two worlds: the earthly and the heavenly. You are not your earthly you. You are also not your spirit. You are truly both. And the path to Nirvana is to walk exactly in the middle of these two worlds. An important reminder to anyone thinking that being 'spiritual' means abandoning the realities and responsibilities of life on this earth. To me, it was beautiful to hear.`,
        `Our monk gets quite personal with us, sharing more about his life. He loves playing the guitar just for pure joy. He loves his daughters. He will soon start teaching Buddhism in the USA which he is really excited about and he keeps working on his English. In the end, he takes a selfie with us - a real digital monk. (Also, whenever he needed to translate anything, he would use the speech to translate feature on his phone). We wish him the best of luck in America and say goodbye.`,
        `On my way back to the hostel and back to work, I am remembering his words: 'When I walk on the street, I keep wishing people to be happy'. I remember I used to do this too but somehow forgot about this completely. I want to make sure to implement it back in my life.`,
      ],
    },
    {
      type: 'text',
      value: [
        `My last day in Bangkok, I am grateful I get to spend with Eif. We start with a stroll at the Kaset fair floating market - pretty much a market on the side of the river, with the stops and restaurants with a great view. This is quite a local and artsy place. We eat a sweet porridge wrapped in a banana leaf and later a full lunch too.`,
        `The second stop is the Metro forest. This serves sort of like a museum of nature, but includes an astonishing earthy coloured architecture.`,
        `In the Lumpini park, we take a long stroll around. I am loving the hidden nature in Bangkok. There are wonderful trees, birds, and a swamp with lotus flowers with a bamboo path above it.`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post2/11.jpg',
          description: ['the floating market'],
        },
        {
          src: '/images/01_southeast_asia/post2/12.jpg',
          description: ['stoppig by for a nice lunch'],
        },
        {
          src: '/images/01_southeast_asia/post2/14.jpg',
          description: ['the nice architecture in the metro park'],
        },
        {
          src: '/images/01_southeast_asia/post2/16.jpg',
          description: ['last hours spent together in Lumpini park'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `Eif sparks my curiosity: 'It is almost the time. At 6 o'clock, you will see something'.`,
        `And truly, the clock hits 6, and all the people in the park suddenly stop and freeze like statues. Only occasional tourists walk by dazzled at what is happening. Eif and I are motionless too. There is some song playing from the radio speaker. Apparently, this is a national song, and every evening at 6 and 8, when it plays, people stop what they are doing out of respect for the country.`,
        `Eif drops me off at the big, loud and busy bus station. We say goodbye but we know we will see each other when I am back in Bangkok at the end of my journey. Who knows what I will experience before I see her again. I am leaving Bangkok extremely grateful for having met her and all the time we spent together.`,
      ],
    },
   ],
  signature: '04/02/2024 - 08/02/2024'
}

export default post2
