import { PostProps } from "@/types"

const post12: PostProps = {
  key: 12,
  journeyKey: 0,
  x: '43%',
  y: '88%',
  zIndex: 110,
  location: 'Crete',
  title: 'In the search of the Minoan civilization',
  content: [
    {
      type: 'text',
      value: [
        `This morning, we were again enjoying a slow breakfast on the balcony. By now, we mapped our environment pretty well. We knew what families live in which houses and made little stories about them. Especially interesting was a big family in a house across the street; we saw the parents driving the kids to school and then getting ready and going to work.`,
        `We used the free public transport to go to town. For that, we had to first walk 30 minutes to the stadium and then we could take the bus. The idea is that people drive to these stops, leave their car in the free parking lot (for example, the one at the stadium), and use public transport to go to town. This system was made to ease the traffic in Heraklion. Amazing. It is made for locals who go to work there, so it is not really advertised in English, but somehow we still managed to find the information and made use of this since then.`,
        `The plan for the day is to visit the Heraklion archeological museum. It contains a lot of artifacts mostly from Knossos palace of the Minoan civilization. It was situated in a beautiful garden with views over the town. My mom stole a lime from one of the trees.`,
        `I learned a lot about the Minoan civilization, which inhabited the island around 5 millennia ago. There were countless vases, one after another, and they kept coming. A lot of little statues, objects for cooking, jewelry, and even a board game kit. What these objects had in common was how beautifully decorated they were. It seemed to me like this civilization had a really good taste in art and aesthetics. I especially loved the spiral symbol - I imagined they were inspired by the waves in the sea. The spiral, as I later learned, symbolizes movement, progressive development, and infinity. There were many other symbols such as seashells and dolphins.`,
        `The part of the museum dedicated to Knossos had a lot of frescoes of beautiful people of the palace. The frescoes gave us a good idea of how people looked and what life in the palace was like. Some people were white and others brown/reddish. It seemed like there was no indication of hierarchy based on the skin color. They were dressed beautifully, had curly hair, long eyeliner, elegant figures, and lots and lots of jewelry. They really liked the color red; other common colors were blue and beige, and they always looked really harmonious. They praised the snake goddess - we saw a wonderful statue of what this goddess looked like. We also saw a lot of horn-shaped statues which were used in the palace a lot. These were probably bull horns and symbolized abundance.`,
        `These museums are always so intense. When we started, we discussed every object and took our time to learn everything and observe everything. But if you wanted to do it so properly, you really would need three days for the whole museum. So as we progressed, we tried to be faster. Especially when we saw the 345th vase.`,
        `As we were leaving, I suddenly ran into a friend from my high school - we were both pretty surprised by this crazy coincidence!`,
        `Later on, we walked through town a little bit, then sat down for coffee and ice cream to get some energy again. The waitress was incredibly kind, beautiful, and sweet; we loved being taken care of by her. She was basically rocking her job.`,
        `We did some jewelry shopping - we both bought something with the spiral symbol. Then we took our free transport back home, walked from the stadium, and managed to go for a swim till the sun went down. In the evening, we watched a political debate, since the Slovak parliamentary elections were just behind the corner.`
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post12/1.jpg',
          description: ['decorated ceramics in the museum'],
        },
        {
          src: '/images/00_balkans/post12/2.jpg',
          description: ['the snake goddess'],
        },
        {
          src: '/images/00_balkans/post12/3.jpg',
          description: ['observing their jewelry', 'this would look good today as well'],
        },
        {
          src: '/images/00_balkans/post12/4.jpg',
          description: ['a vase bigger than mom'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `We went to visit Knossos, the place where everything revolved around here on Crete. The place where they found so many wonderful vases, statues, and frescoes. The site was north of the town, in a little hilly area. There was a bit of a forest there too, so we managed to get some shade. The site was really large and we could walk around there freely.`,
        `The palace was ‘unearthed’ by a British archaeologist Artur Evans. The vases, frescoes, and other objects were preserved and moved to the museum. He rebuilt parts of the palace to resemble the original palace. Some walls were up, together with the red pillars, although it still looked a lot like an untouched archaeological site. When making decisions about how to build it, he got inspired by what was seen on the found frescoes. This means that even though we cannot be sure how the palace truly looked, it should resemble reality.`,
        `The labyrinth was quite mysterious. There were walls going in crazy rectangular shapes and each ‘room’ they created seemed too small to serve a purpose. Our guesses were that it was storage rooms or a prison.`,
        `Inside the palace, we saw the frescoes that we appreciated yesterday in the museum. The North Entrance had beautiful red pillars, which are really iconic. The throne room also had red walls and it looked amazing. I want to believe that this was not a result of Evans’ creativity but the place really looked like this - and better.`,
        `There were countless vases, some of them just laying around the whole site. There were too many. We imagined how Evans discovered another one and went: ‘not again!’`,
        `After visiting the site, I felt like I had a complete picture of this beautiful civilization.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post12/5.jpg',
          description: ['in front of one of the ruins at Knossos'],
        },
        {
          src: '/images/00_balkans/post12/6.jpg',
          description: ['the ruins and restored pillars'],
        },
        {
          src: '/images/00_balkans/post12/7.jpg',
          description: ['the famous throne room'],
        },
        {
          src: '/images/00_balkans/post12/8.jpg',
          description: ['the north entrance with a nice mural'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `We enjoyed another coffee in town. My mom wrote a postcard to her ex colleagues, who gave her part of this vacation as a goodbye gift when she was leaving work. We took the free bus back home and after a while, we realised we were turning in the wrong direction. We got off, made it back to our stop, and made sure to get on the right bus line. We were sitting on a bench and it started raining softly. Everyone tried to hide under the rooftops of shops and cafes, but us, we just sat on the bench in peace.`,
        `In the evening, we went to swim again - and believe it or not, this time we did not forget the goggles! It was amazing; I haven’t snorkeled in years. It gave me a weird boost of dopamine and I couldn't stop laughing.`,
        `At dinner, my mom showed me a video on YouTube from the opening ceremony of the 2004 Olympic Games in Athens. I have never seen a more beautiful performance; I was in awe. It was basically a really beautiful artistic trip down the memory lane of Greece, including a lot of Minoan phenomena. Now I had the context and recognized a lot of symbolism there. I might have fallen in love with this mysteriously beautiful civilization and its people.`
      ]
    }
  ],
  signature: '26/09/2023 - 27/09/2023'
}

export default post12