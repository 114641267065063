import { PostProps } from "@/types"

const post25: PostProps = {
  key: 25,
  journeyKey: 0,
  x: '86%',
  y: '92%',
  location: 'Larnaca',
  title: 'Date in and with the city',
  content: [
    {
      type: 'text',
      value: [
        `It became a pattern: that we interchanged easy-relaxing days with days meant for adventures. For example, the day after the lake experience, we only planned one thing - a date.`,
        `We went to a vibrant Indian restaurant. Thomas made me taste paneer for the first time and it quickly became my favourite. I got slightly tipsy from a really good red wine. After an amazing time spent at that place, we took a stroll to the beach, where we laid and talked for a while.`,
        `The next day was a little bit more active. We decided to walk a different way and suddenly discovered a really cute street leading up to a part of the town we kept missing! But only now did we get the real taste of Larnaca. The town opened up its historical cute little streets to us that we weren’t aware of before.`,
        `First, we found an art exhibition. This was important for me, because I have not seen much art since I started travelling - I focused more on nature and ancient culture. This exhibition, called HOME/HOPE, was especially interesting. It was about home and identity. I loved the art pieces pointing out that we are not bound to one home, nor one identity. The World Citizen passport reminded me how lucky I am to be from a country with one of the strongest passports in the rankings. To anyone interested, here is what the description said about the passport:`,
        `'Passport, please?' For citizens of prosperous, democratic states, it's a casual question; almost a sort of welcome. For the rest of the world, however, it implies suspicion, scrutiny, a screening out of undesirables. Imagine then, a 'world passport', one that would be not just a travel document, but a symbol to unite all mankind instead of an instrument to divide people into national entities.`,
        `In the 60s, former military pilot Garry Davis walked into the US Embassy in Paris to surrender his passport, renounce his citizenship, and declare himself to be a 'citizen of the world'. From that moment, he began travelling the globe with a self-issued 'world passport'. Despite being arrested thirty-two times, his journey continued. Now, over one hundred nations claim to accept the passport, including Burkina Faso, Ecuador, Mauritania, Tanzania, Togo and Zambia. Davis believed that a 'world passport' would be an important step towards bringing humans together, creating a symbol of 'the fundamental oneness and unity of the human community'. More than half a century later, hundreds of thousands of people have a world passport, including Albert Camus, Albert Einstein and Edward Snowden.'`,
        `Do you want one too?`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: `/images/00_balkans/post25/1.jpg`,
          description: ['the t-shirt says it all'],
        },
        {
          src: `/images/00_balkans/post25/2.jpg`,
          description: ['more activist art'],
        },
        {
          src: `/images/00_balkans/post25/3.jpg`,
          description: ['my dream No11 by Hovhar'],
        },
        {
          src: `/images/00_balkans/post25/4.jpg`,
          description: ['world passport for the citizens of the Earth'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `We sat down in the Agios Lazaros church and meditated for a while. It was a beautiful orthodox church from the 9th century! It was visible through the countless decorative ornaments and icons.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: `/images/00_balkans/post25/5.jpg`,
          description: ['Agios Lazaros church tower'],
        },
        {
          src: `/images/00_balkans/post25/6.jpg`,
          description: ['Agios Lazaros from the square'],
        },
        {
          src: `/images/00_balkans/post25/7.jpg`,
          description: ['the ornamental orthodox interior'],
        },
        {
          src: `/images/00_balkans/post25/8.jpg`,
          description: ['part of the ornamental wall is seen in the background'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `Then we walked by a YouTuber sitting at a bench who kept recording the same dialog over and over again: 'Thank you guys for watching my video about this wonderful city - Larnaca…' I wonder now if I could find him, but I would definitely watch that video and give it a thumbs up.`,
        `We did a quick plunge at the beach, and then walked barefoot along the promenade. We reached a little port, where we met a cat. She was big and fluffy, very friendly and slightly sassy.`,
        `'Her name is Mackenzie', said the fisherman that walked up to us. 'She was named after the beach. It's a good cat, she always visits. A really nice British lady takes care of her.'`,
        `The fisherman stayed and we talked. He was a kind man. We explained what we do as an occupation and he expressed his worry - he doesn't know what the advancing technology will bring. We had to admit to him that even computer scientists don't know themselves.`,
        `He said goodbye and threw his cigarette butt on the ground. I just realised that I talked to a man that does the two things that I hate the most: littering and fishing. And yet he still lives in my head as the kind guy. Things aren't so black and white.`,
        `We made it to Kastella beach - Mackenzie was a bit too far. After a bit of relaxing, we decided to go home, this time through more previously undiscovered streets. These were definitely not touristy, but we saw how Cypriots enjoyed their evening in their local pubs.`,
        `While walking, we met our Australian guy from the salt lake! He was discovering the town on a bike. This fun coincidence was a nice conclusion of our day and our time in Larnaca.`
      ]
    },
  ],
  signature: '25/10/2023 - 26/10/2023'
}

export default post25