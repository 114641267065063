import { PostProps } from "@/types"

const post10: PostProps = {
  key: 10,
  journeyKey: 0,
  x: '30%',
  y: '82%',
  location: 'Crete',
  title: 'Planet Earth in action',
  content: [
    {
      type: 'text',
      value: [
        `We enjoyed a slow breakfast on the terrace and made a plan to go to Balos today. A complete north-western tip of the island, with beautiful lagoons and islands around.`,
        `On the way there, we had to get some gas. When we stopped at the station, the gas personnel was already running after us with the gas hose. He almost started putting the gas in, as we shouted: ‘We don’t know which type of fuel goes in there!’ He turned to us with a big chilled-out worry-less smile and said: ‘But we know.’`,
        `When we were 10 kilometers away from Balos, the navigation showed we still had 40 minutes to go. We guessed that the highway changes to a rocky rollercoaster-like road - and we were right. Luckily, we were not the only rental car that went on this crazy off-road. There were plenty, and they all took it slow and steady. My mom was charmed by goats chilling in the shade of a church on the side of the road. We entered a national park and soon we were at a big rocky parking lot.`,
        `From here on, we walked down a staircase and saw the beautiful Balos. There was a giant ship arriving at the same time and hundreds of people spread into the lagoons, looking like an ant colony from a distance.`,
        `The water was azure-blue and crystal clear. We went swimming immediately and enjoyed the pinkish tones reflecting from the sky. We walked into all the waters there and walked around everywhere. We were in a little paradise.`,
        `In one of the lagoons, we really started exploring the bottom of it. Too bad we did not take the diving glasses - we keep forgetting them and here they would be really useful. Luckily, the water where we laid was so shallow we could clearly see everything.`,
        `We noticed some black soft formations in the lagoon. We could take it apart and bend it. Eventually, we started breaking it and realized it smells like asphalt. Later, I Googled about it and saw some theories that it was from the oil from the boats - which is kinda sad.`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/00_balkans/post10/1.jpg',
          description: ['me and the view over Balos'],
        },
        {
          src: '/images/00_balkans/post10/2.jpg',
          description: ['the bright blue water', 'however, including the black tar'],
        },
        {
          src: '/images/00_balkans/post10/3.jpg',
          description: ['shells in the rocks'],
        },
        {
          src: '/images/00_balkans/post10/4.jpg',
          description: ['my collection of sea treasures', '(which I also left behind)'],
        },
      ]
    },
    {  
      type: 'text',
      value: [
        `The best memory from Crete was formed here. My mom noticed a live seashell. We picked it up, but quickly decided to give it some peace and laid it back down. Slowly, the seashell started opening up and we could see the meaty part inside. It extended a tentacle which was the same size as its body and investigated its environment. Later, it used it to dig itself into the sand - and closed itself again. This repeated every few minutes, until the seashell was completely hidden under the sand. I wondered, how many of such seashells are underneath us right now.`,
        `We stayed and observed the seashell for a good half an hour - long enough to burn my bum again.`,
        `Before leaving, we had some coffee in the only café on the island - I like how they do not pollute the space with unnecessary shops and places to eat and there is always only one such thing. When we were still wet from the water, we made sure to climb up the staircase to go back to the car - this protected us from the crazy heat.`,
        `On the way back, we had a plan to still go to Chania, but chose to omit this. We were pretty tired and full of insights; Chania probably would not etch into our memories anyway. We stopped at an olive tree farm for a pee break. Sadly, my mom lost her sandals there. Unfortunately, every such vacation or traveling experience asks its sacrifice; I have given many at this point.`
      ]
    }
  ],
  signature: '23/09/2023'
}

export default post10