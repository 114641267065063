import { PostProps } from "@/types"

const post1: PostProps = {
  key: 1,
  journeyKey: 1,
  x: '46%',
  y: '48%',
  zIndex: 110,
  location: 'Bangkok',
  title: 'More nature, art, science, religion, everything,...',
  content: [ 
    {
      type: 'text',
      value: [
        `In the morning, I take care of my own program. I choose to do one of the touristy things, The Grand Palace. I pay 13€, which for a Thai place, is the most you will ever pay. The palace is a large collection of buildings, temples, and a presidential palace. It is full of ornaments, a photographic heaven. However, we can barely enter any of the buildings, they have closed them down during lockdown and never opened them again. I decide to wait for a free English tour at 10AM and in the meantime, the place gets completely packed by tourists. The tour lady is a bit older and has a weak voice, not louder than all the tourists roaming around. I am not learning much about this place. Thinking back, I wish I had skipped the Grand Palace and gone to see a big temple instead.`,
        `On the way back, I pick up some lunch, coffee, and walk through the port market, which has a strange charm. I accidentally appear in a college and walk through a canteen. Close to Khao San, I discover a really cute but touristy street where restaurants and shops are anything but Thai - but tourists seem to love it here. And the shops have no price tags, so the shop owners make the best of it. I am almost at the hostel, about to meet up with Eif who turns out to be free this afternoon!`,
      ],
    },
    {
      type: 'images', // grand palace
      value: [
        {
          src: '/images/01_southeast_asia/post1/1.jpg',
          description: ['overwhelmed', '(but in a good way)'],
        },
        {
          src: '/images/01_southeast_asia/post1/2.jpg',
          description: ['gold is everywhere'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `We drive up to an island called Ko Kret. This is an Island in the river in the northern park of Bangkok. Eif has never been here and says that tourists don't come here. I feel lucky she knows about these places. We take a boat for 3 Baht and find ourselves in paradise.`,
        `We rent bicycles. The houses here are built on big pillars to survive the rainy season. Even now, there is a swamp in most places. People work on the streets, processing their grains and fruits. Eif teaches me to recognize coconut trees, mango trees and banana trees. Soon enough, I can recognize them from a good distance. There are many of them here, attracting birds that sing like I have not heard them before. We bike through the village on this elevated small road. Cars have no access to this island, only (motor)bikes do. The first temple we see, we immediately hop in. We rest in silence, then Eif explains the strange royal family relations on a big picture displayed in the temple. I am still at the stage where I do not want to miss any temple whatsoever - but quickly find out there are too many temples on this island to go inside every single one of them. Eif finds my enthusiasm funny. Cute little restaurants and cafés overlook the river. We choose one for lunch. At this point, exhaustion hits - especially me, as Eif seems unaffected by the heat.`,
      ],
    },
    {
      type: 'text',
      value: [
        `We take a breather in Eif's apartment. We share more details of our lives, our family and friends. I already knew Eif was a good person but when she explains about her charity activities, I realize I have not met such a selfless person in a really long time. I am wondering whether the hyper individualism of the western culture pushes humans too much apart. Eif put her family, and people in need in the first place.`,
        `We end the day at a night market and then Eif drives me home. I feel really taken care of and I am not sure how to ever repay her sweetness.`,
      ],
    },
    {
      type: 'images', // Ko Kret + night market
      value: [
        {
          src: '/images/01_southeast_asia/post1/3.jpg',
          description: ['cute cycling path in Ko Kret'],
        },
        {
          src: '/images/01_southeast_asia/post1/4.jpg',
          description: ['a temple yard with the typical benches'],
        },
        {
          src: '/images/01_southeast_asia/post1/5.jpg',
          description: ['they do really like ornaments and colors!'],
        },
        {
          src: '/images/01_southeast_asia/post1/6.jpg',
          description: ['my very first asian market visit', 'many many more to come!'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `This day is what I would call a rest day. Eif and I drive to a city park. Eif packed a picnic breakfast and painting supplies! We spend the morning painting in the park. We focus on a giant tree in front of us. It has curly branches and sunbeams shine through it. We listen to calming music and paint in silence. It feels like therapy.`,
        `In the afternoon, we clean up and let the paintings dry in the grass. We take a walk around the park and joke about how unfortunate it would be if someone stole our paintings and got rich off of them. The park shocks me with tiny alligators swimming and walking around in harmony with people. Probably Komodo dragons.`,
        `The next visit is a cool science fair in a really big expo building. There are many schools from many counties showing off their inventions and findings. I especially love an eco-friendly soap which alleviates the damage when people in Bali wash their clothes in the rivers. Another interesting one is a leftover seaweed which can be compressed and used as a sound insulator - and could be used in buildings in cities. The kids from Indonesia are especially active and once we enter their aisle, it takes a while to get out of it. We learn about all their projects and before I know it, I feel their joy and energy, and hold many of their souvenirs and gifts in my hands.`,
        `Today, we have everything - art, science and religion. We walk up to the Golden Mount. A really special place in the heart of Bangkok that soon became my favorite. Here you leave the mess and craziness of the city and enter a really calm, meditative, spiritual realm. As you walk up the stairs, you hear the singing prayers of the monks and countless little bells. The journey up the stairs feels like a journey to peace. The temple at the top reflects the yellow setting sun. Here, we rest and relax and observe the city from higher up.`,
      ],
    },
    {
      type: 'images', // science fair + golden mount
      value: [
        {
          src: '/images/01_southeast_asia/post1/7.jpg',
          description: ['painting in the park'],
        },
        {
          src: '/images/01_southeast_asia/post1/8.jpg',
          description: ['the science fair'],
        },
        {
          src: '/images/01_southeast_asia/post1/9.jpg',
          description: ['Golden Mount temple', 'we rested on these benches for a long time'],
        },
        {
          src: '/images/01_southeast_asia/post1/10.jpg',
          description: ['the top of the temple'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `The only thing missing is actually seeing the sunset. Instead, the sun suddenly disappears in a layer of smog. Eif tells me it did not used to be like this - just before corona, you could still see the sunset here. Since the pandemic, people got used to using their cars and avoiding public transport. Now, cars have completely swallowed the city and people refuse to use public transport again. I can also understand that. For example, Eif has her office 3 hours away from her home. That is how giant Bangkok is. If she needs to be present at the office, I can't imagine how long it would take with public transport. Building a reliable infrastructure takes years and is not always the top priority.`,
        `We finish the day with a light show at the Makahan Fort (part of Design Week). We sit on the grass and watch beautiful projections accompanied by fitting, dreamy music.`,
      ],
    },
   ],
  signature: '02/02/2024 - 03/02/2024'
}

export default post1