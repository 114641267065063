import { PostProps } from "@/types"

const post22: PostProps = {
  key: 22,
  journeyKey: 0,
  x: '90%',
  y: '85%',
  zIndex: 130,
  location: 'Famagusta',
  title: 'The mysteries of the Ghost town',
  content: [
    {
      type: 'text',
      value: [
        `It felt amazing to wake up at our AirBnb. I was extremely grateful I managed to get here despite the plot twists in my travel plan. The comfort and privacy of this place was incomparable to those in hostels - and for the same price, as now I get to share it. Travelling in a pair is economical!`,
        `Our place was a little romantic garden house, decorated and painted in turquoise. The host was a very pleasant and friendly woman. She owned three cute doggies who came up to us in the garden to say hello and we quickly grew fond of them. Two of them had the same name, which I found pretty curious. They were all rescued.`,
        `After our first day of working, we left the house and decided to explore a little bit of the beach side. As it was now already late October, the sun was setting down at around 6pm. We found it strange because at the same time, it was 30 degrees and felt like summer.`,
        `As we were walking down the road towards the beach, we noticed they drive on the left side of the road. We were glad we made the decision not to rent a car - we would probably not want to drive here. The plugs are also different - like those in the UK. This is due to the British influence, as it was under its rule until the 60s. There are still some UK territories to this day. And this is in a country which is now mostly separated into the Turkish and Greek parts - the Greek one being in the EU. Complicated.`,
        `When we arrived at the beach, it was already dark. We entered the water very slowly and carefully, as there were these coastal sharp big rock formations and we did not want to cut our feet on them. Especially now that it got so dark outside. Eventually, we found a little lagoon which mostly had sand in it and decided to dip in there. This was not a regular touristy beach; it was rather in the forest and close to an industrial port. The port was a little source of light, together with the night sky. There were groups of people scattered around, listening to nice music and camping around with their cars, fishing chairs and tents. The atmosphere there was amazing.`,
        `Two cute little puppies found interest in our bags. We needed to send them away, but felt bad that the dogs got so scared of us.`,
        `Later we moved towards the town centre. We walked along a really long fortress by the main road leading us to our goal. Before we were ready, we appeared in front of a half-ruined cathedral - and another one was right behind it. I have never seen anything like this. Famagusta is now a ghost town, countless buildings were left abandoned, some half - or completely - demolished. But most were preserved for others to witness. I found it really strange. We were not really educated on why this was the case. Later we learned from a local what sad event was a precursor of this.`,
        `On the way home, we decided to have dinner in a Turkish diner. It was basically fast food - but the price was as if we just ate at a proper restaurant. Back then we still did not know just how pricey Cyprus actually is.`,
        `We also learned that the English name for the town is Famagusta, but the Turkish call it Gazimagusa, but mostly Magusa - which led to some confusion before. Today became the first day of unravelling these Cypriot mysteries one by one, and discovering one of the most strange countries to its deepest core.`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: `/images/00_balkans/post22/1.jpg`,
          description: ['one furry friend guarding our door'],
        },
        {
          src: `/images/00_balkans/post22/2.jpg`,
          description: ['another furry friend joining me at work'],
        },
        {
          src: `/images/00_balkans/post22/3.jpg`,
          description: ['a wall of an old cathedral now in ruin'],
        },
        {
          src: `/images/00_balkans/post22/4.jpg`,
          description: ['a random ruin we encountered'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `The next day was really full of experiences - I still don’t want to believe this all happened in one day. We worked till noon, then checked out and went towards a lake close by, where we saw some flamingos! We also tried to get to another lake, but ended up in a dense ‘jungle’ and realised it was practically impossible to get there.`,
        `Afterwards, we headed to town. Since it was pretty far, we decided to have our first Cypriot hitch-hiking experience. We ended up having so so many. If I have one advice to people who travel here - try this. It is surprisingly smooth and easy, and also a lot of fun!`,
        `We walked around the city centre and saw everything in the bright daylight. We walked into the first (partially ruined) cathedral and discovered inside it is now a mosque.`,
        `From there, we walked to the ghost town beach. We relaxed there for a moment. Eventually, we decided to swim - when I realised I left my swimsuit in the luggage we kept at the AirBnb for now. I wanted to swim quite badly, so I decided to forget what people might think and just went in the water with my dress on. I made sure to also shower in the dress thoroughly, to wash away the salt.`,
        `It was not so nice that I later had to walk with completely wet clothes on and with wet hair - today was cloudy and windy. My technique to avoid shivering uncomfortably was to imagine I was warm from the inside. It’s all in your mind.`,
        `We walked on towards the ghost town. Here, more and more buildings were abandoned. We decided to explore an old kindergarten or primary school. The glass in the windows and doors were shattered on the ground. The paintings from fairytales on the walls gave me a sad feeling. Whatever happened in this school, I hoped the kids were safe.`,
        `Eventually, we entered the ghost town and understood there is a whole untouched part of the town where literally all buildings were abandoned and untouched. We looked at the map and since we did not have that much time left in the day, we planned our route through the town. There was a service for borrowing bikes and we wanted to bike through the town, but no matter how many bikes we tried, they were all incredibly broken. Maybe that is why you were meant to first choose the bike and only then come to the cashier.`
      ]
    },
    {
      type: 'text',
      value: [
        `The ghost town was jaw-dropping. It was like being transported to the 70s. The original roads, traffic signs, bus stops, houses, shops, pharmacies, everything. It felt like time travel towards the past, but the vacancy also made it feel a bit post-apocalyptic.`,
        `We stopped at one busy spot with toilets and a restaurant and ordered some spring rolls.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: `/images/00_balkans/post22/5.jpg`,
          description: ['partly demolished cathedral which is now a mosque:', 'this is only visible once you enter inside'],
        },
        {
          src: `/images/00_balkans/post22/6.jpg`,
          description: ['abandoned demolished school'],
        },
        {
          src: `/images/00_balkans/post22/7.jpg`,
          description: ['the ghost town'],
        },
        {
          src: `/images/00_balkans/post22/8.jpg`,
          description: ['the beach at the ghost town'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `We hitchhiked back to the AirBnb to pick up our luggage. The easiest destination to communicate was an Irish pub which was maybe 5 minutes from our AirBnb. The driver was so kind he dropped us off right at the entrance of the pub and we awkwardly pretended we were actually going in there before we saw him leave.`,
        `Our next stop was Ayia Napa. We hitchhiked and eventually ended up with an interesting personality. We do not remember his name exactly but believe it was something like Alfredo. We asked about what happened to Famagusta and he explained that the town was invaded by the Turks in the 70s. He was then just 5 years old and had to grow up in a refugee camp because of this. I asked him whether he remembers the invasion itself and he said: ‘I can still see it, like a movie’. We had goosebumps.`,
        `Alfredo also let us know he had a lot of influence in this region and a lot of people know him. Almost sounded like the mafia. He could take a joke and just laughed when Thomas asked him: ‘what are you hiding in the tires?’ He had some shops and businesses and every now and then he pointed out: ‘this is my store’. When we were crossing by Lidl, he told us ‘here is Lidl’, since we asked whether there were any around. ‘Is that also yours?’ asked Thomas. Alfredo laughed - he was clearly an important person but did not take himself too seriously.`,
        `Eventually, we had to cross the border. Now, we had to convince the policemen to let Thomas leave the Turkish side with only his European ID card. He could enter it without problems previously when he travelled to Famagusta through the border, so we believed there should not be any troubles. We tried to explain in different ways and formulations, adding more and more information every time to make the story more complete for him. Eventually, he gave up and let us go. The Greek border went more smoothly.`,
        `There, we saw Alfredo was still waiting for us and took us in his car again. He repeated he has influence and claimed he told the border control to let us through - whether this is true or not, we were thankful.`,
        `Alfredo had to go a different way, so he left us maybe 15 minutes away from Ayia Napa. Here, we immediately stopped a car. There was a friendly couple of a Cypriot guy and a Ukrainian girl and they played loud party music. We were in Ayia Napa in no time - ready for new insights and adventures.`
      ]
    }
  ],
  signature: `19/10/2023 - 20/10/2023`
}

export default post22