import { PostProps } from "@/types"

const post0: PostProps = {
  key: 0,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Bangkok',
  title: 'Long journey and a warm welcome',
  content: [ 
    {
      type: 'text',
      value: [
        `There are three flights I am taking to Asia. At the Vienna airport, everything goes smoothly. I know the airport well.`,
        `On the plane, however, I start questioning whether this was the right choice. The layover in Abu Dhabi is going to be stressful, since I booked the connecting flight through a different company. Therefore, I need to re-check my bags and most likely even switch terminals. If I miss the flight, there will be no compensation, and I will have to buy a new ticket. One hour before landing, I keep checking the time often and try to feel with my body whether we are already descending.`,
        `Luckily, we arrive in Abu Dhabi on time. I have about two and a half hours, plus I am told I do not need to switch terminals as I thought. I am relieved.`,
        `The airport is lush, spacious, neat and slightly futuristic. The immigration control guy scares me by saying that I need a return flight ticket from Thailand, because they might deny my entry if I fail to show it upon request. Once I am at the gate, I immediately take care of my hunger, then my thirst and finally my flight ticket back home - even though I wish I could postpone this decision. I board my plane to Mumbai.`,
        `In Mumbai, I am already a lot more relaxed. This time, I do not even need to leave the transit area. The airport has carpets and a strange, yet pleasant smell. It feels more like someone’s home than an airport. Instead of a sign saying 'transit', there is a person yelling out: 'Bangkok passengers, here!'. He collects everyone and brings us to a security check. They have a printed out paper with our names and cross them out one by one. I find the lack of digitalization quite charming. Men go through the regular security check as we know it. I (as a woman) am requested to go to a hidden booth with curtains where a female worker checks for metals.`,
        `I meet an Austrian guy who has the exact same itinerary as I do. I haven't slept and I went through a lot of stress, so I do not feel very social. He does not read my signs and joins me as I wait at the gate. It is not a good match, he has a lot of energy but I am conserving any energy I have left for myself. I look in the distance as he blasts into my ear. He decides it is a good idea to start telling scary stories from Bangkok. It is my fault I could not tell him I do not wish to listen to that.`,
        `Once we land in Bangkok, I am in disbelief that all transfers went smoothly and nothing was late. It is now around 3 in the afternoon. The Austrian guy has a place close to the big main colorful, and loud road, Khao San, which is also where I need to go. We join forces, take the airport train and taxi. I cheer up once I see the strange city. I haven't slept in 24 hours, but the views from the train spark my interest.`,
      ],
    },
    {
      type: 'text',
      value: [
        `I eat my first street food for dinner. Bangkok is loud and feels quite polluted. I feel a lump growing in my throat. The weather feels heavy, humid. It is cloudy. The restaurant is playing some Thai TV shows, and I watch them with interest. Then I switch my view to the street. I mostly see Thai people working in their small stands on the street. I see a lot of motorbikes, either parked on the side of the road or roaring when passing by. Frequent traffic. Above the street, large clusters of cables hang. I am feeling a little out of place as this is the strangest place I have ever been to. The furthest from what I know. I understand it will need some getting used to.`,
        `My hostel is a little gate to a world which I find more familiar, at least the common room looks like an interior which could be European. I try my best to stay awake till 10. But it is hard, I barely have any presence left in my body and I know if I just rest my eyes a bit, I will fall asleep.`,
        `I wake up in the middle of the night briefly. I do not recognize this bed, the smell, the room. I panic and try to figure out whether I am in danger. It takes me a good minute to remember I am in Bangkok and laugh at my strange spontaneous ideas. I really did travel all the way to Bangkok. I go back to sleep amused by myself.`,
      ],
    },
    {
      type: 'text',
      value: [
        `The next morning, I am meeting up with Eif, a Thai girl I connected to through a Facebook group for female travellers. She is happy to show me around her city, and I feel really lucky to spend time with her—she turned out to be quite a blessing during my first week in Thailand. My first shock from the strange new world immediately melts away once I start connecting with her.`,
        `She picks me up with a car, we park and then share breakfast. My first breakfast is my favorite breakfast - muesli with yoghurt and exotic fruit.`,
        `The next step is a temple visit. I experience my first awe as I enter, not knowing I will feel this same sensation almost every day as I pass by or enter many gorgeous temples. We sit in front of the large Buddha statue and Eif explains little customs from the religion.`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post0/1.jpg',
          description: ['first time seeing a golden buddha'],
        },
        {
          src: '/images/01_southeast_asia/post0/2.jpg',
          description: ['...and a golden temple'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `At the riverside, we participate in one such custom. We buy live fish from the street, which would otherwise end up being sold for food at the market. We say a prayer and release the fish into the river, giving it a new life. My only wish is that they are not being caught again, and that people who sell them for this purpose actually rescue them from the market.`,
        `Eif shares that there is Bangkok Design Week happening right now. Basically, a lot of art and design, discussions and concerts all around the city. I gasp. I love this stuff! We drive to one such location but get a little distracted by a boat ferry. We decide to take it. The ferry costs just 5 Baht (which is around 10 cents). We cross the river and because we're here, we enter the shopping mall at the River city. It is really big and fancy and I see many brands I would find in Europe. The mall has an awesome view on the top, plus some art displays.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post0/3.jpg',
          description: ['art display at the River city rooftop'],
        },
        {
          src: '/images/01_southeast_asia/post0/4.jpg',
          description: ['a sculpture on the building', 'and a nice view on Bangkok'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `Next, we go to Thailand Creative and Design Center which has many floors of displayed design items. The theme of the design week this year is 'livable space', and that can be seen reflected in most of the exhibitions. It is good to see the awakening towards sustainability in Bangkok. It has a long way to go. Plastic is a daily part of life - it is everywhere. The public transport is not great, walking is not a good experience and cars and motorbikes have conquered the city.`,
        `However, young people seem to be eager for a change. Also, the mayor of Bangkok, as Eif told me, is a really inspiring person. He uses regular transport, not fancy black cars with blacked out windows. He set up a place for homeless people where they can use the toilet and take a shower. He motivates people to go to the park by going for a run himself regularly. We saw a part of an exhibition, with many postcards from him, asking people 'How can we make Bangkok better?' people then respond on the back of the postcard and attach them to a wall so that others can read it too. People feel he is connected with them.`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post0/5.jpg',
          description: ['we saw all kinds of strange designs'],
        },
        {
          src: '/images/01_southeast_asia/post0/6.jpg',
          description: ['Eif and I enjoying a sea of giant lightbulbs'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `After seeing two buildings with exhibitions, looking through the Talat Noi street art, we pass by the Aurum Gallery and cannot help but get sucked in. The art there is absolutely breathtaking and it might be one of the best galleries I've been to. The building offers even more art, but I enter the state where you cannot really receive any more visual beauty, it just does not get to you anymore.`,
        `Eif drives me back home through China Town to observe the vibrant nightlife there. What a wonderful end to a wonderful day. I could not have imagined I would see so much beauty and learn so much just in one day. I cannot express my gratitude to Eif.`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post0/7.jpg',
          description: ['Talat Noi was full of cool asian street art like this one'],
        },
        {
          src: '/images/01_southeast_asia/post0/8.jpg',
          description: ['tuktuks parked close to Talat Noi'],
        },
        {
          src: '/images/01_southeast_asia/post0/9.jpg',
          description: ['art in Aurum Gallery', 'this is by Keithen Carter'],
        },
        {
          src: '/images/01_southeast_asia/post0/10.jpg',
          description: ['more from Aurum'],
        },
      ]
    },
  ],
  signature: '30/01/2024 - 01/02/2024'
}

export default post0