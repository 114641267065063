
import { PostProps } from "@/types"

const post6: PostProps = {
  key: 6,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Pai',
  title: 'A hippie paradise at which I got sick',
  content: [ 
    {
      type: 'text',
      value: [
        `This Thursday, I feel a bit better. Although the crazy van ride is not easy on me. I am moving to Pai, through the big mountains. It is the burning season and the smell creeps into the car through all the smallest cracks and openings. I can see the smoke rising through the mountains. It burns in my lungs and my eyes. It upsets me, but mostly for the amount of pollution this creates. As I am later told, the burning season means that farmers (illegally) burn their crops to cultivate the farmland.`,
        `I get dropped off in the village of Pai. It takes about 30 minutes of walking alongside the road to find my hostel, which is a bit offside. I might be the only one walking here; all the tourists are happily roaming around on their scooters. Tourists upon tourists. Where are the locals?`,
        `I look around and see many cute cafes, stores, restaurants, art workshops, but a large portion seems to be owned by westerners.`,
        `Pai is a very special place. If you want to see what ‘hippie’ is, you have to go to Pai. Everyone is looking hippie, wearing hippie clothes, having a large hippie smile. I see many posters dedicated to full moon ceremonies, cacao ceremonies, ecstatic dances, techno parties. And perhaps most frequently - healing sessions with hints on mushrooms, weed, LSD, which seems to be easily available just anywhere behind the corner. I start to wonder whether I am hippie enough for this place.`,
        `I finally make it to the Deejai hostel. I picked this hostel, since it offers many free activities: a nature walk today, later a cacao ceremony and ecstatic dance. Every morning, there is a yoga practice, and every afternoon an ice cold bath.`,
        `I check in but I am told there is no nature walk today, instead, a bunch of English dudes are doing a crossfit workout and tell me I can join that - but that is really not my energy today. So I sit in a hammock and read a book while they grunt and pant around.`,
        `The atmosphere here is unlike anywhere else. People seem so free, everyone is just doing their own thing. A guy practising for a fire show. Some people playing with the slackline. A girl hoola-hooping, some people just doing yoga or meditation somewhere in the garden. The garden is spacious enough to accommodate all kinds of hobbies and play. Play - that’s it - this place feels like a playground and all these adults play with the joy of a child.`,
        `I look around, then I look ahead of me and see a large open field where the garden ends. Behind the field are the mountains. I can still see the smoke.`,
        `A girl who looks like an elf takes the second hammock, she offers me some tea and smokes some weed. We talk a little, later she stands up, puts on headphones and just dances around.`,
        `It’s about time to eat, so I go to a wonderful vegetarian place, called Earth tone. It is so hippie and healthy! Next I go to an even more beautiful place, a cafe in a very simple wooden structure, with white curtains that are being moved in the wind and another view towards the field. Here, the silence sounds even more silent. It is called Dream cafe and I could not think of a more fitting name.`,
        `I make it back to the hostel just in time for the cacao ecstatic dance, but the plans change again quite a bit. There is sadly no cacao (this upsets me, since I have been drinking ceremonial cacao some time ago and I am really missing it)! Another thing is that the ecstatic dance is more like a contact dance. This means there is a lot of focus and interaction, instead of just free wild dancing.`,
        `The instructor tells us about some principles of contact dancing. Basically, you work with your body weight. There are a few types of movement, for example sliding or shifting weight. We first learn to move in this way, letting the body weight lead us. Later, when we get better and better, we start interacting. Here, you react not just to your own body weight but also to that of your partner. You can either support their weight or they support yours. Eventually, it feels like a natural dance that has a will of its own. It must be interesting to see a group of 16 people dance all over the platform like this. Even though I hoped for ecstatic dance tonight, I find contact dance to be quite interesting.`
      ],
    },
    {
      type: 'images', // deejai x2, earths tone, dream cafe
      value: [
        {
          src: '/images/01_southeast_asia/post6/0.jpg',
          description: ['on the way towards the hostel'],
        },
        {
          src: '/images/01_southeast_asia/post6/1.jpg',
          description: ['the Deejai hostel with a nice deck, garden and a view'],
        },
        {
          src: '/images/01_southeast_asia/post6/2.jpg',
          description: ['my vegan lunch at the Earth\'s tone'],
        },
        {
          src: '/images/01_southeast_asia/post6/3.jpg',
          description: ['the Dream cafe'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `Today’s morning yoga was amazing. Even though these activities are run by the volunteers, you can tell they have a lot of experience in what they are facilitating.`,
        `I am having breakfast at the Earth’s tone again. I am reading the book ‘Women who ran with the wolves’. An elderly hippie man from Ireland approaches me, curious about what I read. ‘That is amazing!” he reacts, clearly recognizing and celebrating the book. ‘Have you read it?’ I ask. ‘Of course! All men should read it! It has such deep wisdom on the female soul!’`,
        `I came back for today’s waterfall trip, but this one also got cancelled. Instead, the organiser decided to go to the pool.`,
        `I have no interest in a day at the pool, since I am in such a beautiful nature. I stay sitting at the table, thinking what to do. That is when I meet a guy from Israel (I do not remember his name, so we can call him Michael). He is looking for an activity, so we make a decision to book a taxi to the waterfalls.`,
        `The place is rather wonderful. What is special about this waterfall is that you can literally slide down on it. The rock is soft and there is a bucket that you can fill with water to make it more glidy. It looks a bit scary, the slide is high and steep. But I try it anyway and I slide really fast into the water. So fast I lose my sunglasses. I wish I could do this all day long, but Michael is just standing and waiting for me. After some swimming time, we get some coffee and then hike around the forest a little bit.`,
        `The conversations we have are quite challenging. Michael is really smart and tries to challenge everything, so the conversation requires my brain to run faster. We talk a lot about growth, about unlearning things, about unbecoming, about pain and intense trauma, scary places to be, and who that makes you into. Or who it could make you into.`,
        `When we talk about travelling, the conversation shifts into meeting girls romantically. I am in a relationship, so for me there is nothing to discuss, but Michael is quite young and he seems a little bit clueless about how to approach girls without crossing their boundaries. I notice from his stories that the way he approaches someone is immediately as a romantic (or sexual) target. I suggest that he should try to be friendly first. Kind. And if you vibe, then you could try to make a move. He does not have any female friends or any female role models in his life, so no wonder he puts girls on a pedestal. As if women were something mystical and complicated, something that needs to be conquered. I told him to become friends with girls first and only when he understands the female soul, he can think about romance. See women as human beings first. I think that so many men just are not friends with girls and it shows - that is why they do not understand us. Men who have a good relationship with their mother or a sister already have an easier start, but this is not Michael’s case.`,
        `The conversations were challenging. I think I might have given him good insights just like he did to me in other topics. We eat dinner once back in the village and we say goodbye on the road. He is going back to the hostel, while I attempt to hike to the top of the mountain where the great statue of Buddha can be found.`,
        `I struggle upwards, calling with my boyfriend on the way up. Suddenly, there is an Austrian guy on a motorbike by my side, asking if I want a ride. When we are on top, I make sure to tell my boyfriend I am okay. The Austrian guy understands - he is also travelling solo while being in a relationship. Suddenly, I feel so safe with him. We talk a bit about how we experience it and honestly, it is nice to share this with someone who understands. We look down at the view and sometimes back to the big Buddha. The view is breathtaking either way.`,
        `He brings me back to the hostel on the motorbike and I am grateful. It is already dark outside.`,
        `I reunite with Puck. We sit on the yoga platform, eat dinner and exchange our new experiences. It feels wholesome.`,
        `Today got me thinking a lot about men and how they see women, about their understanding of our needs and gifts. About protecting our safety. I think every guy is at a different stage of this learning journey. With this on my mind, I fall asleep.`
      ],
    },
    {
      type: 'images', // buddha view
      value: [
        {
          src: '/images/01_southeast_asia/post6/4.jpg',
          description: ['the giant Buddha statue at the hill'],
        },
        {
          src: '/images/01_southeast_asia/post6/5.jpg',
          description: ['sunset over Pai'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `Today’s morning yoga is a little bit too flowy for me - it looks more like dance. I am slowly learning what I like and dislike in yoga. I am feeling a little nauseous but so did I in Chiang Mai and I was fine.`,
        `I went to a new place for breakfast, a cute artsy tree house. Then I moved to have coffee at a nice deck that overlooks a little lake.`,
        `Here, I am writing my blog. I am laying down and not really realising I am getting worse. Slowly I understand my whole body is hurting. I want to get up and leave, because I worry soon I won’t be able to. It already feels like I am falling asleep. I want to stand up but it feels like I weigh 300 kilos.`,
        `I get up, patiently wait for my bill and try not to faint or puke. I get to the hostel safely, although half-asleep. I take a cold shower to soothe my body. I feel very hot so put on a bikini and fall asleep.`,
        `I wake up some time later and go to the kitchen to get some water. And right there at the garden, I see a big fire show, with a big audience, people from hostels from the whole village. I did not realize this was happening tonight. I go sit down on the side of the audience, just in case I get worse. Puck arrives too and sits next to me, we start talking. Suddenly, I notice that talking is a lot of effort for me. That my stomach turns and twists every time I attempt to say even one single word. Still I do not believe I would puke and I try to converse as if I was fine. But I cannot ignore it any longer. I tell Puck I will go somewhere with less people. I stand up to walk away. I walk on the yoga platform. I walk, walk,... then I run.`,
        `I barely make it to the edge of the platform as I vomit over it down into the grass. Luckily, I did not hit the platform. My body feels relieved. The audience is rather far and focused on the fire show, I believe no one saw. I wash my face, wipe my tears and keep sitting on the platform, watching the fire show from a distance for some comfort.`
      ],
    },
    {
      type: 'images', // caffee in tree, deck cafe
      value: [
        {
          src: '/images/01_southeast_asia/post6/6.jpg',
          description: ['a cafe in the trees'],
        },
        {
          src: '/images/01_southeast_asia/post6/7.jpg',
          description: ['last meal before getting really sick and exhausted'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `I take my time to go on gentle walks, attempt to eat some plain bread, and drink water. I end up sleeping a big part of the day, or lying in the hammock, listening to the birds. I guess no authentic relating event for me tonight.`,
        `The next day I get my period and I think how interesting that whenever I get sick, it is when I get my period. I go to the Dream cafe for some comforting silence (although loud British people catch me here too). I encounter a girl from the hostel who tells me there are more people who got sick at the hostel. Actually, I had a suspicion. I heard puking a few times, I just wasn’t sure. We think it might be something from the produce, or it could be related to the smoke from the burning season.`,
        `Today I am leaving Pai and I am a little disappointed. My nature walk was cancelled, my cacao ecstatic dance was neither cacao nor ecstatic. The waterfall nature trip became a pool day. And just today as I am leaving, I am missing an event called ‘Euphoric flow’, which I believe is an ecstatic dance with some connecting talks. I am in a bad mood. I guess this is travel too, I could not be high all the time, it had to go down at some point.`,
        `In the evening, I take a van back to Chiang Mai. I spend the ride clenching a plastic bag in my fist, just in case. Once I am in Chiang Mai, it feels like Thailand again, I see mostly locals. I go to my beloved 7-eleven, do soft stretching yoga and go to sleep. I am in Chiang Mai only to sleep here.`
      ],
    },
  ],
  signature: '22/02/2024 - 26/02/2024'
}

export default post6
