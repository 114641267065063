import { PostProps } from "@/types"

const post23: PostProps = {
  key: 23,
  journeyKey: 0,
  x: '92%',
  y: '89%',
  zIndex: 120,
  location: 'Ayia Napa',
  title: 'Insects, undercurrents and supernatural beauty',
  content: [
    {
      type: 'text',
      value: [
        `With some struggles, we found our apartment. It was a little bit old, strangely structured and humid - but I tried to overcome that bad vibe and relax as much as I could. Thomas had to work on a deadline, so he went to a bakery. In the meantime, I went to do some shopping. Oh and yes, the bakeries are open in the evening on Cyprus and Cypriots happily sip their coffee in the late PM hours.`,
        `I have never been to a more touristy place than Ayia Napa. The stores were painfully overpriced and it was almost impossible to find fresh fruits and vegetables. The assortment mostly consisted of chocolate, chips and Coca-Cola. Also, one whole half of the store was dedicated to terrible touristy souvenirs. I imagined this is how they force the tourists to eat out at the restaurants. I visited three ‘grocery stores’ and it was all the same. I guess tonight I am cooking with canned food only.`,
        `When I returned to the apartment, I had to overcome my disgust again. The place got more humid while I was gone. I noticed a cockroach at the fireplace and managed to squash it. ‘It’s all good, it’s just one cockroach’, I told myself.`,
        `However, while cooking I encountered a lot more and my disgust grew. They started jumping around and crawling out of the crevices of the apartment. I also started noticing how dirty the drawers were. I slowly lost my appetite. To top it off, ants started to march in from all directions too. When a large cockroach decided to start climbing my leg, I screamed, turned off the cooker and stormed out. I found Thomas in the bakery and dramatically announced: ‘We have to move, our place is full of cockroaches’.`,
        `We booked a new apartment, this one was not that much more expensive but it was new, modern and clean.`,
        `Through messages, I informed the owner of the cockroach place about what happened and asked politely whether she would consider giving us a refund. I ended up being verbally attacked and called names. I kept the conversation going because I really believed I could awaken some sense in her - but with no success. I was accused of wanting to use the place for free and disappearing - even after suggesting to pay for only that one night. I was called ‘little woman’, ‘liar’, ‘spoiled’ and ‘stupid’, you name it. The place is called Studio Mint by the sea, but since our stay, the negative reviews were growing and after mine, the place disappeared from Booking.`,
        `The host commented on reviews with nasty words. I want to share one such reaction: ‘They left toilet paper everywhere on the ground, since that is what they eat instead of food’.`,
        `I just allowed this woman to suck even more energy out of me than the cockroaches. It was not worth the 60 euros we gave this god-forbidden place and I should’ve given up earlier. Given my stubbornness when it comes to justice, I continued the conversation for weeks after. If this can be a lesson to anyone, it would be: ‘do not try to argue with aggressive people, it leads nowhere’.`,
        `We left the bakery, packed our backpacks, and escaped the cockroach attack before it was too late. When we got into our new apartment, I let out a big sigh. We finished our dinner and enjoyed it on the balcony overlooking the town. This place was nice and clean and only 10 euros more expensive per night. It defied the claim of the previous host saying ‘cockroaches are everywhere on Cyprus’ - and since that experience we also have not encountered them anymore.`,
        `Another lesson would be: ‘do not book a weird looking place with not so many reviews just because it is the cheapest’ - because you might get several levels better if you get the second cheapest one!`,
        `We went to bed in peace, excited for our next adventure.`,
      ]
    },
    {
      type: 'text',
      value: [
        `The next morning, we headed to Cape Greco - the real reason why we were in Ayia Napa. We took the bus and got off at the caves. There was a big group of people with music and a boat down in the sea. Some people were climbing down the rocky coast to make it to the sea, other braver ones took a shortcut and took a big free-fall jump. We observed that for a while and then decided to walk along the coast to find a more quiet place. We climbed down, settled at a rock, where we put on sunscreen and adored the view.`,
        `This was probably the best moment of the whole Cyprus adventure. The sky was intensely blue, the sea even bluer. The sun was warm. The atmosphere was silent and peaceful. This is the true summer paradise.`,
        `We took our snorkelling goggles and carefully descended to the sea. Here, we turned into children. We kept chasing all kinds of fish together, then coming up to catch some air but mostly to share our amazement of what we saw. The more we looked, the crazier kinds of fish we found. There was pure joy, nothing else. Eventually, we found really big bones and made a story that perhaps a cow fell down into the sea. One particular fish scared us even more than the bones - it was really thin and really long.`,
        `At some point, we took a break in a cave, snuggled up and stayed in disbelief of all that we saw.`,
        `Later on, we hitchhiked to the opposite side of Cape Greco. Here, we looked out into the sea from a nice rocky formation. A big boat full of tourists just had a stop there - they all looked up at us, waved, smiled, and joked around - showing us to jump, or for Thomas to push me off the cliff. ‘People on vacation have such a good mood, don’t you think’, said Thomas.`,
        `We continued hiking towards the blue church (Ayioi Anargiroi) where we could pet many cats. It was now afternoon and we had our first wave of tiredness. However, we managed to hike further to Aphrodite's beach, where we relaxed for a while. Before the sun went down, we realised we would like to snorkel a little more - so we kept moving along the coast, observed fish and chased the sun while it was there.`,
        `At some point, the sun was really down, so we accepted it was the time to go home. We hitchhiked back to Ayia Napa, desperately tried to find some vegetables in the stores, then improvised a dinner and watched a movie. What a day.`,
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: `/images/00_balkans/post23/3.jpg`,
          description: ['the water caves'],
        },
        {
          src: `/images/00_balkans/post23/4.jpg`,
          description: ['gorgeous shade of blue'],
        },
        {
          src: `/images/00_balkans/post23/5.jpg`,
          description: ['a cat relaxing in a window church'],
        },
        {
          src: `/images/00_balkans/post23/6.jpg`,
          description: ['the blue church'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `The next morning, we hitchhiked to the sculpture park. This was a wonderful place, full of white statues densely scattered around the path. Most statues depicted something from Greek mythology. We tried to remember what each story was about - but realised we need a proper re-education.`,
      ]
    },
    {
      type: 'images',
      value: [
        {
          src: `/images/00_balkans/post23/8.jpg`,
          description: ['the sculpture park'],
        },
        {
          src: `/images/00_balkans/post23/7.jpg`,
          description: ['The Suicide of Minotaur by Nikolay Karlykhanov'],
        },
      ]
    },
    {
      type: 'text',
      value: [
        `Later on, we moved to Green bay. We hoped we could get lucky. Apparently, it is possible to spot turtles in the sea and there are also some underwater sculptures.`,
        `We went in the water a few times, then came back again when we got cold. We took a break in a nearby stand with some good food and coffee. We decided to take our one last chance to find some turtles or statues while we had some energy and the sun was up.`,
        `Thomas estimated an approximate location of the statues according to a really bad map we found online. Then we swam forwards and kept diving and exploring the sea’s floor. At some point, we were pretty far, in between two peninsulas. We kept looking around, but no statues were to be found. ‘Should we get back?’ suggested Thomas but I did not want to give up just yet. It took us so long to get there! I kept diving, swimming around and scanning the environment. One moment I went back up to catch some breath and I noticed we were suddenly behind the buoy.`,
        `I remembered that it is not advised to go beyond the buoy also because there might be unpredictable undercurrents. I panicked and started swimming towards the beach - realising just how far we are. It might have been just in my head, but I really felt like the sea was pulling me backwards and swimming was harder than usual. ‘Let’s go back!’ I commanded without explanation. ‘I think I want to try again!’ said Thomas, who caught his second wind. ‘No, let’s go back.’ I insisted and tried my best to hide, ignore, and not feel my panic. We swam back towards the sea with all our power. Again, maybe it was just my brain, but I started to feel out of breath. My arms were getting numb. At some point, we noticed one of the peninsulas was actually more closed than the beach, so we started swimming towards there. I tried my best to stay focused and resist my urge to start shouting ‘help!’ and waving my hands around. At some point, I knew we would make it, but I truly only relaxed once we reached a rock we could climb.`,
        `We went to the beach and sat on the blanket. I admitted to Thomas that I thought we were gonna die. Apparently, he hasn’t felt the current, but was willing to admit there perhaps was one. As we talked and relaxed, I was looking in the distance, where we were before and now it did not seem so far. Did I completely overreact? One thing I knew for sure - I would definitely never swim that far for so long again. And maybe I have unlocked a new fear - the fear of the unpredictable and mysterious depths of the sea.`,
        `We accepted the situation - no statues and no turtles today. We hitchhiked back to Ayia Napa to pick up our luggage and catch the bus. Next destination - Larnaca!`
      ]
    }
  ],
  signature: `21/10/2023 - 22/10/2023`
}

export default post23