import { createStore } from 'vuex'
import { JourneyProps, PostProps } from '../types'
import postsBalkans from '../posts/00_balkans/posts'
import postsSoutheastAsia from '../posts/01_southeast_asia/posts'

import router from '@/router'

interface RootState {
  journeys: JourneyProps[]
  selectedJourneyIndex: number
  posts: { name: string; path: string; data: PostProps; }[]
  selectedPostIndex: number
  viewMode: string
  notFound: boolean
}

const store = createStore<RootState>({

  state: {
    journeys: [
      {
        name: 'the balkans',
        path: 'balkans',
        folder: '00_balkans',
        image: 'balkans.jpg'
      },
      {
        name: 'southeast asia',
        path: 'southeast-asia',
        folder: '01_southeast_asia',
        image: 'southeast-asia.png'
      },
    ],
    selectedJourneyIndex: 0,
    posts: postsBalkans,
    selectedPostIndex: 0,
    viewMode: 'map',
    notFound: false,
  },

  mutations: {
    SET_SELECTED_JOURNEY_INDEX (state, index: number) {
      state.selectedJourneyIndex = index
    },
    SET_SELECTED_POST_INDEX (state, index: number) {
      state.selectedPostIndex = index
    },
    NEXT_JOURNEY (state) {
      state.selectedJourneyIndex = Math.min(state.selectedJourneyIndex + 1, state.journeys.length - 1)
    },
    PREV_JOURNEY (state) {
      state.selectedJourneyIndex = Math.max(state.selectedJourneyIndex - 1, 0)
    },
    NEXT_POST (state) {
      state.selectedPostIndex = Math.min(state.selectedPostIndex + 1, state.posts.length - 1)
    },
    PREV_POST (state) {
      state.selectedPostIndex = Math.max(state.selectedPostIndex - 1, 0)
    },
    SET_VIEW_MODE (state, viewMode) {
      state.viewMode = viewMode
    },
    SET_NOT_FOUND (state, notFound) {
      state.notFound = notFound
    },
    SET_POSTS (state, journeyIndex) {
      const posts = [
        postsBalkans,
        postsSoutheastAsia
      ]
      state.posts = posts[journeyIndex]
    }
  },

  actions: {
    nextPost ({ commit, state }) {
      if (state.selectedPostIndex < state.posts.length - 1) {
        commit('NEXT_POST')
        const postPath = this.state.posts[state.selectedPostIndex].path
        const journeyPath = this.state.journeys[state.selectedJourneyIndex].path
        router.push({ path: `/${journeyPath}/${postPath}` })
      }
    },
    prevPost ({ commit, state }) {
      if (state.selectedPostIndex > 0) {
        commit('PREV_POST')
        const postPath = this.state.posts[state.selectedPostIndex].path
        const journeyPath = this.state.journeys[state.selectedJourneyIndex].path
        router.push({ path: `/${journeyPath}/${postPath}` })
      }
    },
    goToPost ({ commit, state }, index) {
      commit('SET_SELECTED_POST_INDEX', index)
      commit('SET_POSTS', state.selectedJourneyIndex)
      const postPath = this.state.posts[state.selectedPostIndex].path
      const journeyPath = this.state.journeys[state.selectedJourneyIndex].path
      router.push({ path: `/${journeyPath}/${postPath}` })
    },
    nextJourney ({ commit, state }) {
      if (state.selectedJourneyIndex < state.journeys.length - 1) {
        commit('NEXT_JOURNEY')
        commit('SET_POSTS', state.selectedJourneyIndex)
        const journeyPath = this.state.journeys[state.selectedJourneyIndex].path
        router.push({ path: journeyPath })
      }
    },
    prevJourney ({ commit, state }) {
      if (state.selectedJourneyIndex > 0) {
        commit('PREV_JOURNEY')
        commit('SET_POSTS', state.selectedJourneyIndex)
        const journeyPath = this.state.journeys[state.selectedJourneyIndex].path
        router.push({ path: journeyPath })
      }
    },
    goToJourney ({ commit, state }, index) {
      console.log('jo')
      commit('SET_SELECTED_JOURNEY_INDEX', index)
      const journeyPath = this.state.journeys[state.selectedJourneyIndex].path
      router.push({ path: journeyPath })
    },
  },

  getters: {
    colorClass (state) {
      return {
        'green': state.selectedJourneyIndex === 0,
        'blue': state.selectedJourneyIndex === 1
      }
    }
  }
})

export default store
