
import { PostProps } from "@/types"

const post4: PostProps = {
  key: 4,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Chiang Mai',
  title: 'Discovering the town: temple photoshoot, yoga and bars',
  content: [ 
    {
      type: 'text',
      value: [
        `On Monday, Duman and Rutvik left already. I haven't really seen the girls, but Laurent is constantly sitting at the breakfast table. I join him and we keep talking, other people coming and leaving, or sometimes staying. I get to know about Laurent's life in the french island Réunion and I feel quite inspired to go see it one day. I enjoy the talks and decide this morning will be for resting. I think in my normal 'busy' life in Europe, we never really get to do this. Just sit for hours and talk without feeling the need to check our phones or keep an eye on the time passing.`,
        `I dedicate the next morning to a yoga practice. There is this wonderful thing: free yoga classes in the park, happening almost every morning with skilled teachers. Today we have a lovely woman from Myanmar, Chocho. The location is a beautiful white wooden platform decorated with flowers in the middle of the lake. Chocho is really skilled and makes the practice very flowy. Suddenly, I feel an intense focus. I am connected to my body. I enjoy every single movement. I am aware of exactly how my body bends and shifts weight. I follow the breathing instructions and something clicks in me. I think I just opened the door to what yoga might be about. I feel it for the first time. It is no longer just an exercise.`,
        `We finish the practice with meditation. At the end, I stand up and walk over to Chocho to thank her and ask more about her teaching, and whether I could find her online. Then I take a walk in the park and I cry a little from the bliss I just experienced and that is still lingering around.`,
      ],
    },
    {
      type: 'images', // yoga in the park
      value: [
        {
          src: '/images/01_southeast_asia/post4/0.jpg',
          description: ['the lake in the middle of the park'],
        },
        {
          src: '/images/01_southeast_asia/post4/1.jpg',
          description: ['the best yogsa spot'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `The next morning, I am having breakfast with some new hostel arrivals until a very energetic being asks: 'Hi! May I join you for breakfast?' She has short, curly ginger hair and a fashionable outfit. Her name is Puck and she comes from the Netherlands. I enjoy her extrovertedness and we agree to go on a temple hunt after breakfast.`,
        `Puck finds a lot of interest in a specialty coffee place and enjoys smelling different coffees. She is very creative, a musician and videographer. I notice that she has a deep curiosity for everything around her. She carries around a good quality Fujifilm camera. We experience the temples not just as a spiritual place, but a beautiful place to do some art.`,
        `We take pictures of the temples and also of ourselves in that setting, taking turns as models and photographers. I learn how to take pictures but Puck also gives me advice on how to pose more naturally. ‘Move slowly, like you are in the water.’ I feel like our clothes match the aesthetics of the temples.`,
        `After noon, we take a break and sit down on a bench, allowing for more conversation. I notice that Puck shares a lot of my values. She clearly educates herself a lot - I notice a lot of wisdom in her. Somehow, I stumbled on another amazing and inspiring person. I love how travel communities are full of amazing people and I feel lucky enough to meet them.`
      ],
    },
    {
      type: 'images', // temples with Puck
      value: [
        {
          src: '/images/01_southeast_asia/post4/2.jpg',
          description: ['a decorated golden temple'],
        },
        {
          src: '/images/01_southeast_asia/post4/3.jpg',
          description: ['cozy temple interior'],
        },
        {
          src: '/images/01_southeast_asia/post4/4.jpg',
          description: ['in front of the lens'],
        },
        {
          src: '/images/01_southeast_asia/post4/5.jpg',
          description: ['behind the camera'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `On Thursday morning, I take my chance again to experience the free yoga class in the park. This time it is with an Indian teacher. He is also a doctor in India. This class is a bit more tough and gives me shaky legs. Yoga can make you strong too - if you persist!`,
        `At the end of the class, Dr Shakti's invited people with back pain to stay with him after the class. He sat down, another person sat in front of him facing him with the back. They put their arms criss-cross on their chest. The doctor then hugged their ribcage and bent backwards. People wrinkled their faces in pain, and sometimes even screamed a little right before there was a big relief. They left the doctor with a light smile or laughter. I tried it myself too, with a little fear, but the crack was indeed very pleasant.`,
        `Most people left by now, but I stay and watch how Shakti's treats more serious cases. People come to him with pain that they have felt for years. Shakti's has them sit or stand a certain way, aligning their bones and muscles very precisely in a specific placement. Then he cracks or stretches them somehow, promising an improvement within a few weeks.`,
        `I will try it too! I am standing with my arms above my hands, my feet turned slightly in strange directions, before I bend forward and let the doctor pull my arms. They show me a before and after picture of my spine and it does seem somehow more aligned now.`,
        `I'm leaving the practice feeling zen and light again. I am meeting up with Puck in a very cool traditional Chinese tea place not far from the park. There is a whole ceremony of how you prepare the tea. We first get a lesson on how to do it right, and later we are able to do it ourselves each time we want another cup. This ceremony results in a small (shot-like) cup of tea, which makes you savor it more, smell it more.`,
        `We plan to go chill at another café, one where we have to take a taxi for 30 minutes. It is the Zombie café very close to the Mae Sa Waterfall. The name of the café is terrible, and it seems to be a bit American (judging on the menu offer), but the place itself is unbelievable. It is set on a river, there are multiple houses with cute windows and balconies in a beautiful nature garden all around. It is quite a big complex and we can choose whichever place to sit down. We eat lunch here and even though we can't always help it, we try not to talk but to get some chill time reading. While traveling, one wants to be surrounded by all these amazing people but can easily forget to spend time without intense socializing or wild experiences.`,
        `After lunch, we embark on the hike towards the waterfalls. I grasp at the jungle. While I saw a bit of nature in the elephant sanctuary some days ago, there were too many distractions for me to actually notice the flora of the jungle. Now, I am looking at every single tree and celebrating the giant leaves, strange shapes, undiscovered shades of green, crazy wild roots twisting and turning at the ground or branches dragging from the tall giant trees down towards us like those in the Tarzan movies. Some trees are entangled and grown into each other. I find joy in picking leaves that are bigger than my head. I keep finding bigger and bigger ones, discarding each previous one until I discover the biggest leaf of my life.`,
        `We jump right into the waterfall pool. It is very refreshing. We swim towards the waterfall and plunge ourselves into it. It is very loud and strong, but manageable as the waterfall is not very tall. We scream from excitement.`,
        `On the way back home, in the taxi, we watch the sunset in silence and complete peace.`,
      ]
    },
    {
      type: 'images', // tea with Puck, waterfall, zombie cafe
      value: [
        {
          src: '/images/01_southeast_asia/post4/6.jpg',
          description: ['our tea ceremony'],
        },
        {
          src: '/images/01_southeast_asia/post4/7.jpg',
          description: ['finding another peaceful place in the Zombie cafe'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `Later in the evening, I reunite with Duman and together with Lisa, we go see the market. Markets in Thailand are a whole new thing. They are very down to earth, cozy. I love taking a walk in the markets on late warm evenings. Somehow they are equally busy and peaceful. Everything is new to me. There are many amazing food stalls with things I've never eaten but that will soon become my regular diet while in Asia. My favorite thing about markets is the tiny colorful plastic tables and chairs. This could be considered kids furniture, you are almost sitting on the ground while eating. It is so humble and charming. There are quite some clothes, crafts and jewelry stands too. If the clothes stand has a lot of elephant print, you know they are targeting tourists. The market in Chiang Mai is a blend of tourism and authenticity.`,
        `We pick up some nice food for dinner. For me, it is a mango sticky rice and an avocado smoothie (honestly the best smoothie in existence, so creamy and not sweet). Duman (since he is an interpreter and enjoys learning languages) teaches me how to ask for my mango sticky rice. However, I don't reproduce it very well because when I ask at a stand where to find it, the lady sends me to 7-eleven.`,
        `We end the day at a nice jazz bar where I reunite with Puck and introduce her to Duman and Lisa. We sit and listen to a really good performance. I am getting my first taste of the traditional Chang beer. This is the only jazz place that is not completely crowded and honestly, we don't understand why.`
      ],
    },
    {
      type: 'text',
      value: [
        `Friday morning, I decide to go to My Secret Café to plan the next steps of my journey. I will be leaving Chiang Mai in a couple of days. Next I meet up with Duman for some lunch and coffee. We want to get a massage but the place turns out to be closed.`,
        `We manage to form a group for a karaoke night. In the north-east side of the town, there is a whole bar scene happening, looking like there is an effort to make this place into Ibiza. We go to Gasoline and I get to experience the beautiful voice of Duman, and a real professional performance from Puck. I tried some songs too, although I could not leave my seat and walk around the room performing for the bar. But these two are naturals.`,
        `I leave the group for a moment for a quick veggie curry dinner at the food and while enjoying it on my tiny plastic chair, I meet more people from the hostel who came with even more people from the hostel. In some time, my group from Gasoline also joins and suddenly there is a big Midway Activity hostel gathering. We move onto the next karaoke bar and this one is almost empty - thus we exchange the microphone with only one other group. I get to sing Unwritten by Natasha Beningfield, my favorite song to scream in a bar. I am supported by Puck, so for a moment I feel like I can sing - but this is because her beautiful voice outpowers mine!`,
        `Most of my group goes home and I stick around with a lot of new people that I did not know before, since there were some plans to go to a techno party. But the group keeps mostly talking for a long while, which is nice, but that is not really my energy right now - I do not really feel like meeting new people. I decide to go home. I see Duman and Lisa, my familiar people, still in the common space and we end up deep talking until late night.`,
      ],
    },
  ],
  signature: '12/02/2024 - 16/02/2024'
}

export default post4