import { PostProps } from "@/types"

const post3: PostProps = {
  key: 3,
  journeyKey: 1,
  x: '26%',
  y: '18%',
  zIndex: 110,
  location: 'Chiang Mai',
  title: 'Making friends, meeting elephants',
  content: [ 
    {
      type: 'text',
      value: [
        `The sleeper bus from Bangkok to Chiang Mai truly is a VIP bus. We get blankets, food, drinks, and I can recline my seat all the way down to a horizontal position. There is a bathroom in the back (no tiny toilet, a real bathroom you walk into). I end up having quite a good night's sleep.`,
        `We arrive at the Chiang Mai bus station. The center is a perfect square shape drawn by remains of the old city walls. The Midpoint Activity Hostel where I will be staying is exactly in the middle of this square.`,
        `I find some people who also go to the center and we share a big Grab taxi. It is way too early for a check in, so we all walk with our giant backpacks to Wat Chedi Luang, probably the most famous temple in the centre. With these strangers, we share some snacks, sit on the benches and observe the temple. We can see the sunrise beams reflect on the 15th century walls. The thing I like the most about the temple is the giant (almost scary) serpent statues at the entrance, and elephant statues at the perimeter up on the temple. It is truly mystical.`,
        `The strangers and I say goodbye and each head to our hostels. I listen to a guy on the phone: 'I went on a jungle trekking, and the next day I went to an elephant sanctuary and bathed with the elephants!' My excitement for this place gets stronger.`,
        `This town in general is a very cozy experience after the busy Bangkok life. There are no giant buildings, no crazy traffic, and the square center is really calm. There are many cute cafes, vegetarian restaurants, and yoga/meditation/art workshops. This is a favorite Thai place for expats. I, too, could see myself living here for a few months and I believe there would always be something to do. Chiang Mai is not too big and once you leave the city, you are in nature, ready for trekking to waterfalls, mountains, or elephant sanctuaries (of which there are many).`,
        `Today, I do a little bit of work, take a long nap and in the evening, I make my way to a drum and bass party. I listen to some nice music, it is a bit early so it is not a rave in a true sense yet. I meet some people, most of them British, they all do laughing gas. Later I learned there is quite a demand for balloons and many bars offer them.`
      ],
    },
    {
      type: 'text',
      value: [
        `The next morning, I make friends at the breakfast. The first person I meet is Duman from Kazakhstan. He is actively searching for people to join him and his friend Rutvik from India for kayaking. To me, this sounds perfect. Soon enough, there is a group of the three of us, plus Laurent from Réunion, and Lisa and Natasha from Russia. We sit together, drink coffee and get to know each other. There is a lot of banter and good chemistry in this group.`,
        `We carpool in a truck to the kayaking spot. Here, we rent each kayak for 50 Baht from the monks (about 1.25€).`,
        `I paddle down the river with my newly made friends. I am learning the right technique from Laurent, who works as a sports teacher. At some point we reach a market. We leave the kayaks behind, stroll through the streets and buy some fruits and traditional sweets. Currently, there are festivities happening because of the Chinese new year.`,
        `On the way back, with fuller bellies, we find a nice shade under a tree and spontaneously decide to rest there. Duman plays handpan drum music from his phone as we all lie down in the kayaks. We look up to the reflections of the light from the water, making nice fluid shapes in the crown of the tree. I am not sure what others are doing, but I close my eyes and meditate. I have a feeling everyone else enters their own peaceful state of mind and somehow, we share this beautiful experience together.`,
        `We did not leave the water without any casualties. On the way back, Duman decided he was close enough to untie his bag from the boat. This is also the moment that he falls over his kayak into the water. Luckily, he was okay, and so were all his belongings (including his phone). We all had a nice laugh about it.`
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post3/1.jpg',
          description: ['Wat Chedi Luang at sunrise'],
        },
        {
          src: '/images/01_southeast_asia/post3/2.jpg',
          description: ['kayaking and chill out'],
        },
        {
          src: '/images/01_southeast_asia/post3/3.jpg',
          description: ['getting refreshments'],
        },
        {
          src: '/images/01_southeast_asia/post3/4.jpg',
          description: ['the famous night market'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `Later in the afternoon, Rutvik and I eat at an amazing vegetarian restaurant. They serve really big portions of amazing quality food for just 50 Bahts. Duman later joins us and we embark on a quick hike to Wat Pha Lat (or the monks trail).`,
        `The story goes that there was an elephant carrying relics to the top of the Doi Suthep mountain. The elephant rested halfway through, and the king commanded the construction of a temple there (Wat Pha Lat). Later, the elephant collapsed and died and the king commanded to build Doi Suthep Temple there. Today, we only have time left for the first temple.`,
        `Duman and Rutvik are a very fun combo, they have travelled for a few days together and they seem to have built a nice friendship. On the way up, we gossip and talk about relationships.`,
        `There are several temples on top, all in a forest, creating a really beautiful mystical complex. We walk around and leave a coin at one of the temples for good fortune.`,
        `On the way back, we talk a lot about the economy and life in India with Rutvik. Later in the evening, we take a stroll in the crazy big night market. Since neither of us three take the lead in navigation, we quickly learned that we end up getting lost because we keep walking and hoping others are paying attention. Luckily, a girl from Switzerland joins us and she takes the lead in navigating us through.`
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post3/12.jpg',
          description: ['with Duman and Rutvik', 'on the way to the hike'],
        },
        {
          src: '/images/01_southeast_asia/post3/10.jpg',
          description: ['at a viewpoint over the jungle'],
        },
        {
          src: '/images/01_southeast_asia/post3/11.jpg',
          description: ['the staircase towards the temples'],
        },
        {
          src: '/images/01_southeast_asia/post3/9.jpg',
          description: ['finally dinner!'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `The next morning I wake up early. On one hand, I am sad that I won’t get to spend the day with my new friends, but on the other hand, I am super excited because I have booked a tour to an elephant sanctuary.`,
        `First, we have a class where we learn more about elephants and the sanctuary. In Northern Thailand, elephants have mostly been rescued and are no longer being used in farming or industry. The sanctuaries here rescued these elephants in the past. Now they preserve them and take care of them. Riding the elephants is luckily not something you would see in Northern Thailand anymore.`,
        `We make medicine made from rice, bananas, turmeric and some other ingredients. We learn some words that the elephants understand to make our contact and feeding them more smooth. And soon enough, we are released into a big field where we can approach an elephant and have a small contact with them.`,
        `I take my time and walk a longer way to two elephants that no one has approached yet. There are two workers taking care of the elephants and watching over the interaction. I happily use my phrases to talk to the elephants and feed them calmly. This works as long as I keep the bucket close to my body, but once I stretch out my arm, the bucket is suddenly empty. Now they let me pet them. I am in awe of how big and beautiful they are. They seem to possess some archaic wisdom. The hard wrinkled skin makes them look old and mystical. One of the elephants seems quite small, so I assume it is probably a 'teenager'.`,
        `Later I leave the space for other people to approach the elephants. Eventually, we go on a walk with the elephants into nature, where they harvest leaves in case they didn't get enough food from the buckets. It is even more magical to see them walk, play, interact and eat in peace. They decide how far to walk, where to eat, and how long we stay there.`,
        `Eventually they get warm and there is time for a bath! We are allowed into the swampy lake with the elephants. This is an opportunity I am not missing, I am trying to ignore the fact that the elephants happily poop into the water. I see this happening, but then one of the keepers digs into the water to take a big perfect circular ball of poop and throws it out of the pool. At least they are trying to keep it sort of clean.`,
        `The elephants get playful and spray water around with their trunks, splashing the people in the pool. We get buckets and are encouraged by the keepers to throw water on the elephants. They lay down in the water and enjoy the refreshing splashes of water, splashing us back every now and then. A French girl I made friends with says: 'I will remember this for the rest of my life'. I have to agree with her.`,
        `We say goodbye to the elephants, take a shower and eat a large meal at the buffet.`
      ],
    },
    {
      type: 'text',
      value: [
        `The drivers bring us to the second activity of the package: a bamboo raft and a hike to a waterfall. The bamboos are at least 10 centimeters in diameter, and maybe 8 meters long. They tie about 12 of them side by side to form a boat. I join a Chinese woman with her mother on their boat. The river is calm and we see a lot of nature around us. The strange bright green jungle trees, but also farms and in my opinion very skinny looking cows.`,
        `The hike to the waterfall was easy and smooth. The french girl is an adventurer at heart and so we end up swimming in the waterfall even though no one else from the group seemed up for that. After some time, we are being called to get back to the drivers. We need to keep up with the agreed schedule.`,
        `I am observing people on the ride back to Chiang Mai. I might be the only one still awake and alert. Despite the wind whooshing through the tuktuk and an uneven road, people keep dropping and readjusting their head in their sleep. The Chinese woman rests hers on my shoulder but I let her relax there while I read my book. It is a very peaceful, hot, and sunny evening.`
      ],
    },
    {
      type: 'images',
      value: [
        {
          src: '/images/01_southeast_asia/post3/5.jpg',
          description: ['after the feeding'],
        },
        {
          src: '/images/01_southeast_asia/post3/6.jpg',
          description: ['trekking with the elephants towards their feeding spots'],
        },
        {
          src: '/images/01_southeast_asia/post3/7.jpg',
          description: ['on the road'],
        },
        {
          src: '/images/01_southeast_asia/post3/8.jpg',
          description: ['at (some) waterfall - before we jumped in'],
        },
      ],
    },
    {
      type: 'text',
      value: [
        `I am relaxing in the common room of the hostel, which is basically an area outside with fans. I meet Duman, and we start talking. Eventually the topic shifts to how to handle rude people. I had an upsetting experience in Bangkok, where the receptionist rolled her eyes and said to me: 'Are you crazy?' when I asked for help with the AC. Since then, every interaction with her was really unpleasant.`,
        `Duman shared some stories that really inspired me. He was once in some canteen sort of restaurant. A lady with a very stiff frown was throwing the food from the spatula onto the places and then aggressively shoving the plates into people's hands. She seemed to have hated her job. You immediately lose your appetite after such treatment. Duman thought about what he could do about this and figured he could compliment her. He thanked her for her service, saying she was very nice. She loved hearing this. Her face lit up, and she suddenly treated every customer with softness and a smile.`,
      ],
    },
  ],
  signature: '09/02/2024 - 11/02/2024'
}

export default post3
